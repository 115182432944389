import React, { useState, useEffect } from 'react'
import axios from "axios"


function Career() {
  const [jobCategory, setJobCategory] = useState('all');
  const handleFilter = (category) => {
    setJobCategory(category);
  }

  const [showModal, setShowModal] = useState(false);
  const [selectedJobId, setSelectedJobId] = useState(null);
  const handleApply = (jobId) => {
    setSelectedJobId(jobId);
    setShowModal(true);
  };
  const closeModal = () => {
    setShowModal(false);
  };

  const [jobs, setJobs] = useState([]);
  const fetchJobs = async () => {
    try {
      const response = await axios.get('https://bt-hrms-pvt-backend.onrender.com/api/user/fetch-opened-jobs',
        { withCredentials: true }
      );
      console.log("hello ", response.data)
      setJobs(response.data);
    } catch (error) {
      console.error('Error fetching jobs:', error);
    }
  };

  useEffect(() => {
    fetchJobs();
  }, []);

  // console.log("Jobs are ", jobs)

  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    dob: '',
    gender: '',
    address: '',
    city: '',
    zipCode: '',
    collegeName: '',
    degree: '',
    branch: '',
    sessionFrom: '',
    sessionTo: '',
    companyName: '',
    position: '',
    year: '',
    email: '',
    phone: '',
    linkedIn: '',
    github: '',
    resume: ''
  });
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async(e) => {
    e.preventDefault();
    console.log("form data is ", formData, "job id is ", selectedJobId);
    try {
      const response = await axios.post(`http://localhost:8000/api/job-apply/${selectedJobId}`, formData,
        { withCredentials: true }
      );
      console.log("hello response is this ", response.data)
      // setJobs(response.data);
    } catch (error) {
      console.error('Error applying for job:', error);
    }

  };
  return (
    <div className="main-page-wrapper p0">

      <div className="fancy-feature-fortyFive lg-container mt-100 pt-100 pb-110 md-pt-80 md-pb-60" id="feature">
        <div className="container position-relative">
          <div className="row">
            <div className="col-xl-8 col-lg-9 m-auto" data-aos="fade-up" data-aos-duration="1200">
              <div className="title-style-eleven text-center mb-50 md-mb-10">
                <h2>Attain, Engage & Retain <span>Customers</span> Were Never So Easy</h2>
              </div>
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="col-lg-4 col-md-6" data-aos="fade-up" data-aos-duration="1200">
              <div className="block-style-forty text-center mt-30">
                <div className="icon rounded-circle d-flex align-items-center justify-content-center m-auto"><img src="images/icon/209.svg" alt="" /></div>
                <h4>Easy to use</h4>
                <p className="pl-xl-5 pr-xl-5">Elit esse cillum dolore eu fugiat nulla pariatur</p>
              </div>
              {/* /.block-style-forty */}
            </div>
            <div className="col-lg-4 col-md-6" data-aos="fade-up" data-aos-duration="1200" data-aos-delay="100">
              <div className="block-style-forty text-center mt-30">
                <div className="icon rounded-circle d-flex align-items-center justify-content-center m-auto"><img src="images/icon/210.svg" alt="" /></div>
                <h4>Task Management</h4>
                <p className="pl-xl-5 pr-xl-5">quis nostrud exerct ullamo ea nisi ut aliqu dolor</p>
              </div>
              {/* /.block-style-forty */}
            </div>
            <div className="col-lg-4 col-md-6" data-aos="fade-up" data-aos-duration="1200" data-aos-delay="200">
              <div className="block-style-forty text-center mt-30">
                <div className="icon rounded-circle d-flex align-items-center justify-content-center m-auto"><img src="images/icon/211.svg" alt="" /></div>
                <h4>Details Report</h4>
                <p className="pl-xl-5 pr-xl-5">Duis aute irure dolor  reprehen derit in voluptat velit.</p>
              </div>
              {/* /.block-style-forty */}
            </div>
          </div>
        </div>
        <img src="images/shape/246.svg" alt="" className="shapes shape-one" />
        <img src="images/shape/247.svg" alt="" className="shapes shape-two" />
        <img src="images/shape/248.svg" alt="" className="shapes shape-three" />
        <img src="images/shape/249.svg" alt="" className="shapes shape-four" />
      </div> {/* /.fancy-feature-fortyFive */}



      <div className="fancy-text-block-twentyTwo lg-container mt-250 md-mt-120" id="whyUs">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-xl-5 col-lg-6 order-lg-last" data-aos="fade-left" data-aos-duration="1200">
              <div className="text-wrapper">
                {/* <a className="fancybox mb-20 md-mb-10" data-fancybox="" href="https://www.youtube.com/embed/aXFSJTjVjw0">
                                        <img src="images/icon/71.svg" alt="" className="icon" />
                                    </a> */}
                <h2>TESTIMONIALS</h2>
                <div className="title-style-seven">
                  <h3>“Working for BharatTech has been nothing short of an incredible experience”</h3>
                </div>  {/*  /.title-style-seven */}
                <h4>2019-2022</h4>
                <a href="#open-position" className="theme-btn-eight">Show Open Positions</a>
              </div>  {/*  /.text-wrapper */}
            </div>
            <div className="col-xl-7 col-lg-6 col-md-8 m-auto order-lg-first" data-aos="fade-right" data-aos-duration="1200">
              <div className="illustration-holder">
                <img src="images/assets/ils_21.svg" alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>  {/*  /.fancy-text-block-twentyTwo */}

      <div className='job-openings' id="open-position">
        <div className='head'>
          <div className='heading'>
            Job Openings
          </div>
          <div className='filters'>
            <button className='filter-btn' onClick={() => handleFilter('all')}>All</button>
            <button className='filter-btn' onClick={() => handleFilter('design')}>Design</button>
            <button className='filter-btn' onClick={() => handleFilter('development')}>Development</button>
            <button className='filter-btn' onClick={() => handleFilter('business')}>Business</button>
            <button className='filter-btn' onClick={() => handleFilter('sales')}>Sales</button>
          </div>
        </div>
        <div className='card-container'>
          {jobs.map((job, index) => (
            <div className='card' key={index}>
              <div className='org'>
                <h5>{job.org_name}</h5>
              </div>
              <div className='title'>
                <h3>{job.jobPosition}</h3>
              </div>
              <div className='location'>
                <h5>Office: {job.officeLocation}</h5>
              </div>
              <div className='department'>
                <h5>Department: {job.department}</h5>
              </div>
              <div className='line'></div>
              <div className='jobtype-salary-slots'>
                <div className='jobtype-salary'>
                  <div className='jobtype'>
                    {job.jobType}
                  </div>
                  <div>
                    |
                  </div>
                  <div className='salary'>
                    Salary : {job.salaryRangeFrom} - {job.salaryRangeUpto}
                  </div>
                  <div>
                    |
                  </div>
                  <div className='salary'>
                    Slots : {job.numberOfSeats}
                  </div>
                </div>
                <div className='apply-btn'>
                  <button onClick={() =>
                    handleApply(job._id)
                  } className='button'>
                    Apply
                  </button>
                </div>
              </div>
            </div>
          ))}
          {
            showModal && (

              <div className='job-apply-form'>
                <div className='job-apply-form-content'>
                  <span onClick={closeModal}>Close</span>
                  <h4>Apply For Job</h4>
                  <form onSubmit={handleSubmit}>
                    <div>
                      <label htmlFor="firstName">First Name:</label>
                      <input type="text" id="firstName" name="firstName" value={formData.firstName} onChange={handleChange} />
                      <label htmlFor="lastName">Last Name:</label>
                      <input type="text" id="lastName" name="lastName" value={formData.lastName} onChange={handleChange} />
                      <label htmlFor="dob">Date of Birth:</label>
                      <input type="date" id="dob" name="dob" value={formData.dob} onChange={handleChange} />
                      <label htmlFor="gender">Gender:</label>
                      <select id="gender" name="gender" value={formData.gender} onChange={handleChange}>
                        <option value="">Select</option>
                        <option value="male">Male</option>
                        <option value="female">Female</option>
                      </select>
                    </div>
                    <div>
                      <label htmlFor="address">Address:</label>
                      <input type="text" id="address" name="address" value={formData.address} onChange={handleChange} />
                      <label htmlFor="city">City:</label>
                      <input type="text" id="city" name="city" value={formData.city} onChange={handleChange} />
                      <label htmlFor="zipCode">Zip Code:</label>
                      <input type="text" id="zipCode" name="zipCode" value={formData.zipCode} onChange={handleChange} />
                    </div>
                    <div>
                      <label htmlFor="collegeName">College Name:</label>
                      <input type="text" id="collegeName" name="collegeName" value={formData.collegeName} onChange={handleChange} />
                      <label htmlFor="degree">Degree:</label>
                      <input type="text" id="degree" name="degree" value={formData.degree} onChange={handleChange} />
                      <label htmlFor="branch">Branch:</label>
                      <input type="text" id="branch" name="branch" value={formData.branch} onChange={handleChange} />
                      <label htmlFor="sessionFrom">Session From:</label>
                      <input type="text" id="sessionFrom" name="sessionFrom" value={formData.sessionFrom} onChange={handleChange} />
                      <label htmlFor="sessionTo">Session To:</label>
                      <input type="text" id="sessionTo" name="sessionTo" value={formData.sessionTo} onChange={handleChange} />
                    </div>
                    <div>
                      <label htmlFor="companyName">Company Name:</label>
                      <input type="text" id="companyName" name="companyName" value={formData.companyName} onChange={handleChange} />
                      <label htmlFor="position">Position:</label>
                      <input type="text" id="position" name="position" value={formData.position} onChange={handleChange} />
                      <label htmlFor="year">Year:</label>
                      <input type="text" id="year" name="year" value={formData.year} onChange={handleChange} />
                    </div>
                    <div>
                      <label htmlFor="email">Email:</label>
                      <input type="email" id="email" name="email" value={formData.email} onChange={handleChange} />
                      <label htmlFor="phone">Phone:</label>
                      <input type="tel" id="phone" name="phone" value={formData.phone} onChange={handleChange} />
                      <label htmlFor="linkedIn">LinkedIn:</label>
                      <input type="text" id="linkedIn" name="linkedIn" value={formData.linkedIn} onChange={handleChange} />
                      <label htmlFor="github">Github:</label>
                      <input type="text" id="github" name="github" value={formData.github} onChange={handleChange} />
                    </div>
                    <div>
                      <label htmlFor="resume">Upload Resume:</label>
                      <input type="text" id="resume" name="resume" onChange={handleChange} />
                    </div>
                    <button type="submit">Submit</button>
                  </form>
                </div>
              </div>

            )
          }



          {/* <div className="modal">
              <div className="modal-content">
                <span className="close" onClick={closeModal}>Close</span>
                <h2>Apply for Job</h2>hell
                <form>

                  <input type="text" placeholder="Your Name" />
                  <input type="email" placeholder="Your Email" />

                  <button type="submit">Submit</button>
                </form>
              </div>
            </div> */}

        </div>
      </div>





      <div className="fancy-short-banner-eleven bg-color mt-70 mb-50 md-mb-20 md-mt-30" style={{ marginTop: '20vh' }}>
        <div className="container">
          <div className="clearfix">
            <div className="row">
              <div className="col-lg-8 m-auto">
                <h2 className="font-rubik" data-aos="fade-up" data-aos-duration="1200">Want to be a part of our talented team?</h2>
                <a href="contact-us(light).html" className="theme-btn-eight btn-lg mt-50 md-mt-30" data-aos="fade-up" data-aos-duration="1200" data-aos-delay="100">SEND YOUR RESUME  </a>
              </div>
            </div>
          </div>
        </div>
        <div className="bubble-one"></div>
        <div className="bubble-two"></div>
        <div className="bubble-three"></div>
        <div className="bubble-four"></div>
        <img src="images/shape/212.svg" alt="" className="shapes shape-one" />
        <img src="images/shape/213.svg" alt="" className="shapes shape-two" />
      </div> {/* /.fancy-short-banner-eleven */}
    </div>
  )
}

export default Career
