import React, { useState } from 'react'
import { SocialIcon } from 'react-social-icons/component'
import teamMemberData from "../data/teamMemberData";

function Team() {
    const [filterMember, setFilterMember] = useState('all');
    
    const handleFilter = (category) => {
        setFilterMember(category);
    }

    const filteredTeamMember = filterMember === 'all' ? teamMemberData : teamMemberData.filter(member => member.category.includes(filterMember));

    return (
        <div>
            <div className="main-page-wrapper">
                <div className="fancy-hero-one">
                    <div className="container">
                        <div className="row">
                            <div className="col-xl-9 col-lg-11 m-auto">
                                <h2 className="font-rubik">We’ve Most Talented Team Members</h2>
                            </div>
                        </div>
                    </div>
                    <div className="bubble-one"></div>
                    <div className="bubble-two"></div>
                    <div className="bubble-three"></div>
                    <div className="bubble-four"></div>
                    <div className="bubble-five"></div>
                    <div className="bubble-six"></div>
                </div> {/* /.fancy-hero-one */}

                <div className="team-section-one">
                    <div className="container">
                        <div className="controls mb-100 md-mb-60">
                            <button type="button" className="control" data-filter="all" onClick={() => handleFilter('all')}>All</button>
                            <button type="button" className="control" data-filter=".leadership" onClick={() => handleFilter('leadership')}>Leadership</button>
                            <button type="button" className="control" data-filter=".designer" onClick={() => handleFilter('advisory')}>Advisory</button>
                        </div>

                        <div className="mixitUp-container">
                            {filteredTeamMember.map((member, index) => (
                                <div className={`mix ${member.category.join(' ')}`} key={index}>
                                    <div className="team-member">
                                        <div className="image-container">
                                            <img src={member.img} alt={member.name} />
                                        </div>
                                        <h4>{member.name}</h4>
                                        <strong>{member.position}</strong>
                                    </div> {/* /.team-member */}
                                </div>
                            ))}

                            <div className="gap"></div>
                            <div className="gap"></div>
                            <div className="gap"></div>
                        </div> {/* /.mixitUp-container */}
                    </div>
                </div> {/* /.team-section-one */}

                <div className="fancy-short-banner-eleven bg-color mt-70 mb-50 md-mb-20 md-mt-30">
                    <div className="container">
                        <div className="clearfix">
                            <div className="row">
                                <div className="col-lg-8 m-auto">
                                    <h2 className="font-rubik" data-aos="fade-up" data-aos-duration="1200">Want to be a part of our talented team?</h2>
                                    <a href="#" className="theme-btn-one btn-lg mt-50 md-mt-30" data-aos="fade-up" data-aos-duration="1200" data-aos-delay="100">SEND YOUR CV</a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="bubble-one"></div>
                    <div className="bubble-two"></div>
                    <div className="bubble-three"></div>
                    <div className="bubble-four"></div>
                    <img src="images/shape/212.svg" alt="" className="shapes shape-one" />
                    <img src="images/shape/213.svg" alt="" className="shapes shape-two" />
                </div> {/* /.fancy-short-banner-eleven */}
            </div>
        </div>
    );
}

export default Team;
