import React from 'react';
import { SocialIcon } from 'react-social-icons/component';
import 'react-social-icons/github';
import 'react-social-icons/facebook';
import 'react-social-icons/linkedin';
import 'react-social-icons/instagram';
import 'react-social-icons/twitter';
import 'react-social-icons/whatsapp';

function Footer() {
    return (
        <footer className="theme-footer-three pt-100">

            <div className="top-footer">
                <div className="container">
                    <div className="row justify-content-between">
                        <div className="col-lg-4 col-12 footer-about-widget">
                            <a href="/" className="logo"><img src="/images/logo/footer-logo.png" alt="" /></a>
                        </div>
                        {/*  /.about-widget */}
                        <div className="col-lg-2 col-md-4 footer-list">
                            <h5 className="footer-title">Links</h5>
                            <ul>
                                <li><a href="/services">Services</a></li>
                                <li><a href="#">Startups</a></li>
                                <li><a href="/contact">Contact Us</a></li>
                                {/* <li><a href="#">Content</a></li> */}
                            </ul>
                        </div>
                        {/*  /.footer-list */}
                        <div className="col-lg-2 col-md-4 footer-list">
                            <h5 className="footer-title">About Us</h5>
                            <ul>
                                <li><a href="/about">About Us</a></li>
                                <li><a href="/team">Our Team</a></li>
                                <li><a href="#">Careers</a></li>
                            </ul>
                        </div>
                        <div className="col-lg-2 col-md-4 footer-list">
                            <h5 className="footer-title">Legal</h5>
                            <ul>
                                <li><a href="/termandcondtion">Terms & Conditions</a></li>
                            </ul>
                        </div>
                    </div>
                    {/*  /.row */}
                </div>
                {/*  /.container */}
            </div>
            {/*  /.top-footer */}

            <div className='social-icons'>
                <SocialIcon url="https://in.linkedin.com/company/bharattech" target="_blank" style={{ width: 45, height: 45 }} />
                <SocialIcon url="http://github.com/" target="_blank" style={{ width: 45, height: 45 }} />
                <SocialIcon url="https://twitter.com/bharattech_in" target="_blank" style={{ width: 45, height: 45 }} />
                <SocialIcon url="https://www.facebook.com/Bharattech.org/" target="_blank" style={{ width: 45, height: 45 }} />
                <SocialIcon url="https://www.instagram.com/bharattech_org/" target="_blank" style={{ width: 45, height: 45 }} />
                <SocialIcon url="http://whatsapp.com/" target="_blank" style={{ width: 45, height: 45 }} />
            </div>

            <div className="container">
                <div className="bottom-footer-content">
                    <div className="row">
                        <div className="col-lg-8 ml-auto">
                            <div className="d-md-flex align-items-center justify-content-between">
                                <p className="order-md-first">Copyright @2024 BharatTech </p>
                            </div>
                        </div>
                    </div>
                </div>
                {/*  /.bottom-footer */}
            </div>
        </footer>
    );
}

export default Footer;
