import React from 'react';

function TermAndCondition() {
	return (
		<div>
			<div className="main-page-wrapper">

				<div className="terms_and_policy">
					<div className="container">
						<div className="row">
							<div className="col-lg-4">
								<ul className="nav nav-tabs">
									<li className="nav-item">
										<a className="nav-link active" data-toggle="tab" href="#opt1">1. Website terms of use</a>
									</li>
									<li className="nav-item">
										<a className="nav-link" data-toggle="tab" href="#opt2">2. Terms and conditions</a>
									</li>
									<li className="nav-item">
										<a className="nav-link" data-toggle="tab" href="#opt3">3. Privacy policy</a>
									</li>
									<li className="nav-item">
										<a className="nav-link" data-toggle="tab" href="#opt4">4. Cookie policy</a>
									</li>
									<li className="nav-item">
										<a className="nav-link" data-toggle="tab" href="#opt5">5. Third Party Links</a>
									</li>
									<li className="nav-item">
										<a className="nav-link" data-toggle="tab" href="#opt6">6. GDPR</a>
									</li>
								</ul>
							</div>

							<div className="col-lg-8">
								{/* Tab panes */}
								<div className="tab-content">
									<div id="opt1" className="tab-pane fade active show">
										<h2 className="font-gilroy-bold">Website Terms & Use</h2>
										<div className="update-date">LAST UPDATED: 09/16/2024</div>
										<h3>1. Acceptance of Terms</h3>
										<p className='hyphens-auto'>By accessing and using the Bharattech Techecosystem Pvt Ltd website <a href='www.bharat-tech.org/'>(www.bharat-tech.org/)</a> , you agree to abide by these Terms of Use. If you do not agree with these terms, please do not use the Website.</p>
										<ul>
											<li><a href="#">Terms and conditions</a></li>
											<li><a href="#">Privacy policy</a></li>
										</ul>
										<h3>2. Intellectual Property</h3>
										<p>All content on the Website, including text, images, and logos, is the property of Bharattech Techecosystem Pvt Ltd and is protected by intellectual property laws. Unauthorized use of this content is prohibited.</p>
										<h3>3. User Conduct</h3>
										<p>You agree to use the Website only for lawful purposes. You must not:
											<br />- Engage in any illegal activities.
											<br />- Interfere with the Website’s operation or security.
											<br />- Upload or transmit any harmful or malicious content.
										</p>
										<h3>4. Account Security</h3>
										<p>If the Website requires you to create an account, you are responsible for maintaining the confidentiality of your login information and for all activities under your account. Notify us immediately of any unauthorized use.</p>
										<h3>5. Third Party Links</h3>
										<p>The Website may contain links to third-party sites. Bharattech Techecosystem Pvt Ltd is not responsible for the content or practices of these external sites. Use them at your own risk.</p>
										<h3>6. Limitation of Liability</h3>
										<p>Bharattech Techecosystem Pvt Ltd is not liable for any indirect, incidental, or consequential damages arising from the use or inability to use the Website.</p>
										<h3>7. Change to Terms</h3>
										<p>We may update these Terms of Use periodically. Your continued use of the Website following any changes signifies your acceptance of the updated terms.</p>
										<h3>8. Governing Law and Jurisdiction</h3>
										<p>These terms are governed by the laws of India, including but not limited to:
											<br />- The Information Technology Act, 2000
											<br />- The Indian Contract Act, 1872
											<br />- The Copyright Act, 1957
										</p>
										<p>Any disputes arising out of or relating to these Terms of Use or the Website will be subject to the exclusive jurisdiction of the courts of Uttar Pradesh, India.
											<br /> By using this Website, you acknowledge that you have read and understood these Terms of Use.
										</p>
									</div>
									<div id="opt2" className="tab-pane fade">
										<h2 className="font-gilroy-bold">Terms & Conditions</h2>
										<div className="update-date">LAST UPDATED: 09/16/2024</div>
										<h3>1. Introduction</h3>
										<p>Welcome to the website of Bharattech Techecosystem Pvt Ltd. By accessing or using our website, you agree to comply with these Terms and Conditions. If you do not agree with any part of these terms, please discontinue using the website..</p>
										<h3>2. Intellectual Property</h3>
										<p>All content, including but not limited to text, graphics, logos, images, and software, is the exclusive property of Bharattech Techecosystem Pvt Ltd and is protected under applicable intellectual property laws of India, including the Copyright Act, 1957, and the Trade Marks Act, 1999. Unauthorized use, reproduction, or distribution of any content from this site is strictly prohibited.
										</p>
										<h3>3. User Responsibilities</h3>
										<p>- Account Security: Users are responsible for maintaining the confidentiality of their account credentials and all activities conducted under their account.
											<br />- Lawful Use: Users must ensure that their use of the website complies with all applicable laws and regulations in India, including but not limited to the Information Technology Act, 2000, and its related amendments.
											<br />- Prohibited Use: Users agree not to use the website for unlawful activities, infringing on intellectual property rights, or attempting to breach security protocols.
										</p>
										<h3>4. Content Submission</h3>
										<p>By submitting content to the website, including but not limited to comments, feedback, or suggestions, you grant Bharattech Techecosystem Pvt Ltd a non-exclusive, royalty-free, and worldwide license to use, modify, and publish the content as deemed necessary, in accordance with the Copyright Act, 1957.</p>

										<h3>5. Third-Party Links</h3>
										<p>This website may contain links to third-party websites. Bharattech Techecosystem Pvt Ltd is not responsible for the content, privacy policies, or practices of any third-party websites. These links are provided solely for convenience, and users access these sites at their own risk.</p>
										<h3>7. Limitation of Liability</h3>
										<p>To the fullest extent permitted by applicable law, Bharattech Techecosystem Pvt Ltd shall not be liable for any direct, indirect, incidental, consequential, or punitive damages arising from the use or inability to use the website. This includes but is not limited to loss of data, revenue, or business opportunities, even if Bharattech has been advised of such damages.</p>

										<h3>8. Privacy Policy</h3>
										<p>We value your privacy and handle your personal information in accordance with the Information Technology (Reasonable Security Practices and Procedures and Sensitive Personal Data or Information) Rules, 2011. Please refer to our Privacy Policy for details on how we collect, store, and use your personal information.</p>

										<h3>9. Indemnification</h3>
										<p>You agree to indemnify and hold Bharattech Techecosystem Pvt Ltd harmless from any claims, liabilities, losses, damages, or expenses arising out of your use of the website, violation of these terms, or infringement of any third-party rights, including intellectual property rights.</p>

										<h3>10. Changes to Terms and Conditions</h3>
										<p>Bharattech Techecosystem Pvt Ltd reserves the right to modify these Terms and Conditions at any time. Any changes will be posted on this page, and it is the user’s responsibility to stay updated with these changes. Continued use of the website after modifications constitutes acceptance of the revised terms.</p>

										<h3>11. Governing Law and Jurisdiction</h3>
										<p>These Terms and Conditions are governed by and construed in accordance with the laws of India, specifically under the jurisdiction of the Indian Contract Act, 1872, and the Information Technology Act, 2000. Any disputes arising from the use of the website shall be subject to the exclusive jurisdiction of the courts in Uttar Pradesh, India.</p>


									</div>
									<div id="opt3" className="tab-pane fade">
										<h2 className="font-gilroy-bold">Privacy policy</h2>
										<div className="update-date">LAST UPDATED: 09/16/2024</div>
										<h3>1. Introduction</h3>
										<p>Welcome to Bharattech Techecosystem Pvt Ltd’s Privacy Policy. This policy outlines how we collect, use, and protect your personal information when you visit our website or use our services. We are committed to safeguarding your data in compliance with applicable Indian laws, including the Information Technology (Reasonable Security Practices and Procedures and Sensitive Personal Data or Information) Rules, 2011, and the Data Protection laws under the Information Technology Act, 2000.</p>

										<h3>2. Information We Collect</h3>
										<p>Personal Identification Information: Such as name, email address, phone number, and other contact details provided when you register or contact us.<br />
											Technical Data: Including IP address, browser type, operating system, and browsing behavior collected through cookies and tracking technologies.<br />
											Usage Data: Information on how you interact with our website and services, such as pages visited and links clicked.</p>

										<h3>3. How We Use Your Information</h3>
										<p>To Provide and Improve Services: Delivering, maintaining, and enhancing our services and customer support.<br />To Communicate: Sending updates, newsletters, and promotional materials about Bharattech. You may opt-out at any time.<br />
											To Personalize Experience: Customizing content and recommendations based on your preferences.<br />
											To Analyze Usage: Understanding website usage and user behavior for better service delivery and security.
										</p>

										<h3>4. Cookies and Tracking Technologies</h3>
										<p>We use cookies and similar technologies to improve user experience and gather data. Cookies are small data files stored on your device. You can manage your cookie preferences through your browser settings. For details on our cookie usage, please refer to our Cookie Policy.</p>

										<h3>5. Data Sharing and Disclosure</h3>
										<p>We do not sell your personal information but may share it in the following situations:
											- With trusted third-party providers who assist us in operating our website and services.<br />
											- To comply with legal obligations or protect rights and safety in accordance with the Information Technology (Reasonable Security Practices and Procedures and Sensitive Personal Data or Information) Rules, 2011.<br />
											- In the event of mergers, acquisitions, or other business transitions, where personal data may be transferred to new owners.
										</p>

										<h3>6. Data Security</h3>
										<p>We implement reasonable security measures to protect your data from unauthorized access, disclosure, or alteration, as required by the Information Technology (Reasonable Security Practices and Procedures and Sensitive Personal Data or Information) Rules, 2011. However, no method of electronic storage or transmission over the internet is completely secure. While we strive to protect your data.</p>

										<h3>7. Your Rights</h3>
										<p>Under applicable Indian laws, you have the right to:
											- Request access to and correction of your personal data.<br />
											- Request deletion of your personal data, subject to legal requirements.<br />
											- Object to the processing of your data for specific purposes.<br />
											- Obtain your data in a structured, commonly used format.
										</p>

										<h3>8. Third-Party Links</h3>
										<p>Our website may contain links to other sites. We are not responsible for their privacy practices. Please review the privacy policies of any third-party websites you visit.</p>

										<h3>9. Changes to This Privacy Policy</h3>
										<p>We may update this policy periodically. Changes will be posted on this page with an updated effective date. Continued use of our website after changes indicates acceptance of the revised policy.</p>

									</div>
									<div id="opt4" className="tab-pane fade">
										<h2 className="font-gilroy-bold">Cookie policy</h2>
										<div className="update-date">LAST UPDATED: 09/16/2024</div>
										<h3>1. Introduction</h3>
										<p>This Cookie Policy explains how Bharattech Techecosystem Pvt Ltd uses cookies and similar tracking technologies on our website. By using our website, you consent to the use of cookies in accordance with this policy.</p>

										<h3>2. What Are Cookies?</h3>
										<p>Cookies are small text files placed on your device by a website you visit. They help the website recognize your device and store information about your visit, including your preferences and browsing behavior.</p>

										<h3>3. How We Use Cookies</h3>
										<p>Essential Cookies:Necessary for the website to function properly, such as enabling you to login and navigate securely.<br />Performance Cookies:Collect information about how visitors use our site, helping us improve its performance.<br />
											Functional Cookies: Remember your preferences and settings, providing a more personalized experience.<br />
											Targeting Cookies: Deliver relevant advertisements based on your browsing behavior.
										</p>

										<h3>4. Managing Cookies</h3>
										<p>You can manage cookies through your browser settings. You can block or delete cookies, but this may affect your ability to use certain features of our website.</p>

										<h3>5. Third-Party Cookies</h3>
										<p>Our website may use cookies from third-party services, such as analytics and advertising providers. These cookies are governed by the privacy policies of the respective third parties.</p>

										<h3>6. Legal Compliance</h3>
										<p>We comply with the Information Technology (Reasonable Security Practices and Procedures and Sensitive Personal Data or Information) Rules, 2011, under the Information Technology Act, 2000 in India. This includes providing transparent information about the types of cookies we use and how they impact your privacy.</p>

										<h3>7. Changes to This Policy</h3>
										<p>We may update this Cookie Policy periodically. Changes will be posted on this page with an updated effective date.</p>

									</div>
									<div id="opt5" className="tab-pane fade">
										<h2 className="font-gilroy-bold">Third Party Links</h2>
										<div className="update-date">LAST UPDATED: 09/16/2024</div>
										<h3>1. Introduction</h3>
										<p>Our website may contain links to third-party websites or services that are not owned or controlled by Bharattech Techecosystem Pvt Ltd. These links are provided for your convenience and reference.</p>

										<h3>2. External Links</h3>
										<p>Content: Bharattech does not control or endorse the content of external websites. Any views or opinions expressed on these sites are those of the respective authors and do not necessarily reflect our views.<br />Responsibility: We are not responsible for the privacy practices, terms of use, or content of external websites. You should review the privacy policies and terms of use of any third-party sites you visit.
										</p>

										<h3>3. Third-Party Services</h3>
										<p>Services: Our website may use third-party services, such as analytics tools or advertising networks, to enhance functionality and user experience. These services may collect information about your online activities.<br />Privacy: We do not have control over how third-party services collect or use your data. Please refer to the respective privacy policies of these services for more information.
										</p>

										<h3>4. User Responsibility</h3>
										<p>Interaction: When you access third-party links or services, you do so at your own risk. We encourage you to read the terms and conditions and privacy policies of any third-party websites or services you interact with.<br />
											Endorsement: The presence of a third-party link on our website does not imply endorsement or affiliation with the linked site or service.
										</p>

										<h3>5. Legal Compliance</h3>
										<p>Indian Laws: This policy is governed by the laws of India. The provisions of the Information Technology Act, 2000, and the rules framed thereunder, including the Information Technology (Reasonable Security Practices and Procedures and Sensitive Personal Data or Information) Rules, 2011, shall apply to all third-party links and services accessed via our website.
											Jurisdiction: Any disputes arising from the use of third-party links or services shall be subject to the exclusive jurisdiction of the courts in Uttar Pradesh, India.
										</p>
									</div>
									<div id="opt6" className="tab-pane fade">
										<h2 className="font-gilroy-bold">GDPR</h2>
										<div className="update-date">LAST UPDATED: 09/16/2024</div>
										<h3>1. Introduction</h3>
										<p>At Bharattech Techecosystem Pvt Ltd, we are dedicated to ensuring that your personal data is managed with the highest level of care and in compliance with both the General Data Protection Regulation (GDPR) (Regulation (EU) 2016/679) and the applicable Indian data protection laws, including the Information Technology Act, 2000, and the rules and regulations made thereunder.</p>

										<h3>2. Data Controller</h3>
										<p>Bharattech Techecosystem Pvt Ltd is the data controller responsible for your personal data. Our registered office is located at [Your Address], [Your City], India.</p>

										<h3>3. Personal Data We Collect</h3>
										<p>We collect and process the following types of personal data:
											- Contact Information: Name, email address, phone number, and postal address.<br />
											- Account Information: Username, password, and other account-related details.<br />- Usage Data: Information about your interactions with our website and services.<br />- Marketing Data: Preferences for receiving marketing communications.</p>

										<h3>4. Purposes of Processing</h3>
										<p>We use your personal data for the following purposes:
											- To provide and manage our services.<br />
											- To improve our website and services.<br />
											- To communicate with you, including sending updates and marketing information.<br />
											- To comply with legal obligations.
										</p>

										<h3>5. Legal Basis for Processing</h3>
										<p>We process your personal data based on:
											- Consent: Where you have given us consent.<br />
											- Contract: To fulfill contractual obligations.<br />
											- Legal Obligation: To comply with legal requirements.<br />
											- Legitimate Interests: For our legitimate business interests.
										</p>

										<h3>6. Data Retention</h3>
										<p>We retain personal data for as long as necessary to fulfill the purposes for which it was collected, comply with legal obligations, and resolve disputes.</p>

										<h3>7. Your Data Protection Rights</h3>
										<p>Under GDPR and Indian data protection laws, you have the right to:
											- Access your personal data.<br />
											- Correct inaccurate or incomplete data.<br />
											- Request deletion of your data.<br />
											- Restrict processing of your data.<br />
											- Obtain and transfer your data.<br />
											- Object to processing of your data.<br />
											- Withdraw consent where applicable.
										</p>

										<h3>8. Data Security</h3>
										<p>We implement measures to protect your personal data from unauthorized access, alteration, and disclosure. Despite these measures, no security method is completely infallible.</p>

										<h3>9. International Data Transfers</h3>
										<p>We may transfer your data to third parties outside of India, ensuring that appropriate safeguards are in place to comply with both GDPR and Indian data protection laws.</p>

										<h3>10. Compliance with Indian Data Protection Laws</h3>
										<p>In addition to GDPR, we comply with the Information Technology Act, 2000, and the relevant rules and regulations governing data protection in India. This includes adherence to guidelines for the collection, processing, storage, and transfer of personal data.</p>

										<h3>11. Changes to This Policy</h3>
										<p>We may update this statement periodically. Any changes will be posted on this page with an updated effective date.</p>


									</div>
								</div> {/* /.tab-content */}
							</div>
						</div>
					</div>
				</div>

			</div> {/* /.main-page-wrapper */}
		</div>
	)
}

export default TermAndCondition
