const teamMemberData = [
    {
        img: "images/team/ceo.png",
        name: "Tushar Trivedi",
        position: "Founder & CEO",
        category: ["leadership"]
    },
    {
        img: "images/team/coo.png",
        name: "Anubha Yadav",
        position: "Co-Founder & COO",
        category: ["leadership"]
    },
    {
        img: "images/team/cmo.png",
        name: "Akashdeep Srivastava",
        position: "Co-Founder & CMO",
        category: ["leadership"]
    },
    {
        img: "images/team/cto.png",
        name: "Akanshu Panchal",
        position: "Co-Founder & CTO",
        category: ["leadership"]
    },
    {
        img: "images/team/1.jpeg",
        name: "Mr. Abhishek Tiwari",
        position: "Mentor",
        category: ["advisory"]
    },
    {
        img: "images/team/2.png",
        name: "Mr. Kamlesh Dwivedi",
        position: "Advisor",
        category: ["advisory"]
    },
    {
        img: "images/team/3.png",
        name: "Mr. Gaurav Tiwari",
        position: "Advisor",
        category: ["advisory"]
    },
    {
        img: "images/team/4.png",
        name: "Dr. Mohit Trivedi",
        position: "Advisor",
        category: ["advisory"]
    },
    {
        img: "images/team/5.png",
        name: "Mr. Suresh Gupta",
        position: "Advisor",
        category: ["advisory"]
    },
    {
        img: "images/team/6.png",
        name: "Mr. R.P. Dubey",
        position: "Advisor",
        category: ["advisory"]
    },
];

export default teamMemberData;