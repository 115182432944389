import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './pages/Home';
import Services from './pages/Services';
import Startup from './pages/Startup';
import About from './pages/About';
import Contact from './pages/Contact';
import Team from './pages/Team';
import TermAndCondition from './pages/TermAndCondition';
import Careers from './pages/Careers';
import Preloader from './components/Preloader';
import Navbar from './components/Navbar';
import Footer from './components/Footer';
import ScrollToTop from './components/ScrollToTop'; // Import the ScrollToTop component
import ScrollToTopButton from './components/ScrollToTopButton';

function App() {
  return (
    <>
      <Preloader />
      <Router>
        <Navbar />
        <ScrollToTop /> {/* Include the ScrollToTop component */}
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/services" element={<Services />} />
          <Route path="/team" element={<Team />} />
          <Route path="/termandcondtion" element={<TermAndCondition />} />
          <Route path="/startup" element={<Startup />} />
          <Route path="/careers" element={<Careers />} />
        </Routes>
        <Footer />
        <ScrollToTopButton />
      </Router>
    </>
  );
}

export default App;
