import React from 'react';

const ScrollToTopButton = () => {
  const handleScrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth' // Optional: Smooth scrolling behavior
    });
  };

  return (
    <button className="scroll-top" onClick={handleScrollToTop}>
      <i className="fa fa-angle-up" aria-hidden="true"></i>
    </button>
  );
};

export default ScrollToTopButton;
