import React, { useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import 'swiper/css/scrollbar';
import { Autoplay, Pagination } from 'swiper/modules';

function Startup() {
    const [activeCollapse, setActiveCollapse] = useState(null);

    const toggleCollapse = (collapseId) => {
        setActiveCollapse(collapseId === activeCollapse ? null : collapseId);
    };

    return (
        <div>
            <div className="main-page-wrapper">
              
                <div className="hero-banner-three">
                    <div className="container">
                        <div className="row">
                            <div className="col-xl-9 col-lg-11 col-md-8 m-auto">
                                <h1 className="font-rubik">Doc software you looking for.</h1>
                            </div>
                            <div className="col-xl-8 col-lg-9 m-auto">
                                <p className="sub-text font-rubik">With deski Docs, you can write, edit, and collaborate wherever you are with 7 day trial</p>
                            </div>
                        </div>
                        <div className="search-filter-form">
                            <form action="#">
                                <input type="text" placeholder="Search Somthing.." />
                                <button><img src="images/icon/54.svg" alt="" /></button>
                                <select className="form-control" id="exampleFormControlSelect1">
                                    <option>All</option>
                                    <option>Layout</option>
                                    <option>API</option>
                                    <option>Doc</option>
                                </select>
                            </form>
                        </div>
                        <p className="sing-in-call">Already using deski? <a href="login.html" className="font-rubik">Sign in</a>.</p>
                        <img src="images/assets/ils_09.svg" alt="" className="illustration" />
                    </div> {/* /.container */}
                    <img src="images/shape/68.svg" alt="" className="shapes shape-one" />
                    <img src="images/shape/69.svg" alt="" className="shapes shape-two" />
                    <img src="images/shape/70.svg" alt="" className="shapes shape-three" />
                    <img src="images/shape/71.svg" alt="" className="shapes shape-four" />
                    <img src="images/shape/72.svg" alt="" className="shapes shape-five" />
                    <img src="images/shape/73.svg" alt="" className="shapes shape-six" />
                    <img src="images/shape/74.svg" alt="" className="shapes shape-seven" />
                    <img src="images/shape/75.svg" alt="" className="shapes shape-eight" />
                    <img src="images/shape/76.svg" alt="" className="shapes shape-nine" />
                    <img src="images/shape/77.svg" alt="" className="shapes shape-ten" />
                </div> {/* /.hero-banner-three */}



                {/* 
			=============================================
				Fancy Feature Eight
			============================================== 
			*/}
                <div className="fancy-feature-eight mt-110 md-mt-80">
                    <div className="container">
                        <div className="row">
                            <div className="col-xl-12 m-auto">
                                <div className="title-style-four text-center mb-80 md-mb-30">
                                    <h2>Create documentation with technical-free writing,
                                        <span> & effortless <img src="images/shape/line-shape-3.svg" alt="" /></span>
                                        hosting.
                                    </h2>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-xl-11 m-auto">
                                <div className="row">
                                    <div className="col-md-6" data-aos="fade-up" data-aos-duration="1200">
                                        <div className="block-style-ten">
                                            <div className="icon"><img src="images/icon/55.svg" alt="" /></div>
                                            <h6 className="title">Personal</h6>
                                            <p>With deski docs, you can write, edit, and collaborate wherever you are. For Free.</p>
                                            <a href="#"><img src="images/icon/56.svg" alt="" /></a>
                                        </div> {/* /.block-style-ten */}
                                    </div>
                                    <div className="col-md-6" data-aos="fade-up" data-aos-duration="1200" data-aos-delay="100">
                                        <div className="block-style-ten">
                                            <div className="icon"><img src="images/icon/57.svg" alt="" /></div>
                                            <h6 className="title">Business</h6>
                                            <p>The deski Docs you love with added security and control for teams.</p>
                                            <a href="#"><img src="images/icon/56.svg" alt="" /></a>
                                        </div> {/* /.block-style-ten */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> {/* /.fancy-feature-eight */}



                {/* 
			=============================================
				Fancy Text block Sixteen
			============================================== 
			*/}
                <div className="fancy-text-block-sixteen mt-200 md-mt-80">
                    <div className="container">
                        <div className="fancy-feature-fifteen mt-120 md-mt-80" id="product">
                            <div className="bg-wrapper">
                                <div className="container">
                                    <div className="sldier-wrapper">
                                        <div id="screenSlider" className="carousel slide" data-ride="carousel" data-interval="200000">
                                            <div className="row">
                                                <div className="col-lg-8 m-auto">
                                                    <ol className="carousel-indicators">
                                                        <li data-target="#screenSlider" data-slide-to="0" className="active">
                                                            <div className="startup-slider">
                                                                {/* <img src="images/icon/90.svg" alt="" />
                                                        <p>Team Box</p> */}
                                                                <div></div>
                                                            </div>
                                                        </li>
                                                        <li data-target="#screenSlider" data-slide-to="1">
                                                            <div className="startup-slider">
                                                                {/* <img src="images/icon/91.svg" alt="" />
                                                        <p>Project & Task</p> */}
                                                                <div></div>
                                                            </div>
                                                        </li>
                                                        <li data-target="#screenSlider" data-slide-to="2">
                                                            <div className="startup-slider">
                                                                {/* <img src="images/icon/92.svg" alt="" />
                                                        <p>Note & Docs</p> */}
                                                                <div></div>
                                                            </div>
                                                        </li>
                                                    </ol>
                                                </div>
                                            </div>
                                            <div className="carousel-inner mt-60 sm-mt-40">
                                                <div className="carousel-item active">
                                                    <img src="images/assets/feature-img-12.png" alt="" className="m-auto" />
                                                </div>
                                                <div className="carousel-item">
                                                    <img src="images/assets/feature-img-12.png" alt="" className="m-auto" />
                                                </div>
                                                <div className="carousel-item">
                                                    <img src="images/assets/feature-img-12.png" alt="" className="m-auto" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>  {/*  /.sldier-wrapper */}
                                </div>
                                <img src="images/shape/168.svg" alt="" className="shapes shape-one" />
                                <img src="images/shape/169.svg" alt="" className="shapes shape-two" />
                            </div>  {/*  /.bg-wrapper */}
                        </div>
                        <div class="progress-bar"></div>
                        <div className="row justify-content-between">
                            <div className="col-lg-3 col-md-4">
                                <div className="block-style-eleven mt-40">
                                    <div className="num font-rubik">01</div>
                                    <div className="title">Personal Notes</div>
                                    <p className="font-rubik">A place to think and track ideas for you and your team</p>
                                </div> {/* /.block-style-eleven */}
                            </div>
                            <div className="col-lg-3 col-md-4">
                                <div className="block-style-eleven mt-40">
                                    <div className="num font-rubik">02</div>
                                    <div className="title">Knowledge Base</div>
                                    <p className="font-rubik">A home for your team, best practices and thoughts.</p>
                                </div> {/* /.block-style-eleven */}
                            </div>
                            <div className="col-lg-3 col-md-4">
                                <div className="block-style-eleven mt-40">
                                    <div className="num font-rubik">03</div>
                                    <div className="title">Products Doc</div>
                                    <p className="font-rubik">Beautiful docs for your APIs, Products, FAQs, & User Guides,</p>
                                </div> {/* /.block-style-eleven */}
                            </div>
                        </div>
                    </div>
                </div> {/* /.fancy-text-block-sixteen */}



                {/*
			=====================================================
				Fancy Feature Eight
			=====================================================
			*/}
                <div className="fancy-feature-eight pt-150 md-pt-100">
                    <div className="container">
                        <div className="title-style-four text-center mb-60 md-mb-40">
                            <div className="row">
                                <div className="col-lg-10 m-auto">
                                    <h6>How it works</h6>
                                    <h2>Awesome place to start creating your
                                        <span>first doc with <img src="images/shape/line-shape-2.svg" alt="" /></span>deski
                                    </h2>
                                </div>
                            </div>
                        </div>

                        <div className="block-style-twelve">
                            <div className="row">
                                <div className="col-lg-6 col-md-8 ml-auto order-lg-last" data-aos="fade-left" data-aos-duration="1200">
                                    <div className="illustration-holder">
                                        <img src="images/assets/ils_10.svg" alt="" />
                                    </div>
                                </div>
                                <div className="col-lg-5 order-lg-first" data-aos="fade-right" data-aos-duration="1200">
                                    <div className="text-wrapper">
                                        <h6>Documentation</h6>
                                        <h2 className="font-rubik title">A home for your team, best-practices & thoughts.</h2>
                                        <p>With deksi docs, you can write, edit, let it collaborate wherever you are lorem  dumy text introduction.</p>
                                    </div> {/* /.text-wrapper */}
                                </div>
                            </div>
                        </div> {/* /.block-style-twelve */}

                        <div className="block-style-twelve">
                            <div className="row">
                                <div className="col-lg-6 col-md-8 mr-auto" data-aos="fade-right" data-aos-duration="1200">
                                    <div className="illustration-holder">
                                        <img src="images/assets/ils_11.svg" alt="" />
                                    </div>
                                </div>
                                <div className="col-lg-5" data-aos="fade-left" data-aos-duration="1200">
                                    <div className="text-wrapper">
                                        <h6>User Guides</h6>
                                        <h2 className="font-rubik title">Docs for your APIs, products, FAQs and user guides,</h2>
                                        <p>With deksi docs, you can write, edit, let it collaborate wherever you are lorem  dumy text introduction.</p>
                                    </div> {/* /.text-wrapper */}
                                </div>
                            </div>
                        </div> {/* /.block-style-twelve */}

                        <div className="block-style-twelve">
                            <div className="row">
                                <div className="col-lg-6 col-md-8 ml-auto order-lg-last" data-aos="fade-left" data-aos-duration="1200">
                                    <div className="illustration-holder">
                                        <img src="images/assets/ils_12.svg" alt="" />
                                    </div>
                                </div>
                                <div className="col-lg-5 order-lg-first" data-aos="fade-right" data-aos-duration="1200">
                                    <div className="text-wrapper">
                                        <h6>Combine Idea</h6>
                                        <h2 className="font-rubik title">A place to think and track ideas for you & your team</h2>
                                        <p>With deksi docs, you can write, edit, let it collaborate wherever you are lorem  dumy text introduction.</p>
                                    </div> {/* /.text-wrapper */}
                                </div>
                            </div>
                        </div> {/* /.block-style-twelve */}
                    </div>
                </div> {/* /.fancy-feature-eight */}



                {/*
			=====================================================
				Client Feedback Slider Two
			=====================================================
			*/}
                <div className="client-feedback-slider-two mt-180 md-mt-100">
                    <img src="images/shape/78.svg" alt="" className="shapes shape-one" />
                    <img src="images/shape/79.svg" alt="" className="shapes shape-two" />
                    <img src="images/shape/80.svg" alt="" className="shapes shape-three" />
                    <img src="images/shape/81.svg" alt="" className="shapes shape-four" />
                    <img src="images/shape/82.svg" alt="" className="shapes shape-five" />
                    <img src="images/shape/83.svg" alt="" className="shapes shape-six" />
                    <img src="images/shape/84.svg" alt="" className="shapes shape-seven" />
                    <img src="images/shape/85.svg" alt="" className="shapes shape-eight" />
                    <div className="container">
                        <div className="title-style-four text-center mb-100 md-mb-60">
                            <div className="row">
                                <div className="col-lg-7 col-md-9 m-auto">
                                    <h6>Feedback</h6>
                                    <h2>What’s Our Client Say
                                        <span>About Us <img src="images/shape/line-shape-2.svg" alt="" /></span>
                                    </h2>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="slider-content">
                        <Swiper
                            spaceBetween={2}
                            slidesPerView={1}
                            loop={Infinity}
                            autoplay={{
                                delay: 2500,
                                disableOnInteraction: false,
                            }}
                            pagination={{
                                clickable: true,
                            }}
                            modules={[Autoplay, Pagination]}
                            breakpoints={{
                                640: {
                                    slidesPerView: 1,
                                    spaceBetween: 10,
                                },
                                768: {
                                    slidesPerView: 2,
                                    spaceBetween: 10,
                                },
                                1024: {
                                    slidesPerView: 3,
                                    spaceBetween: 10,
                                },
                            }}
                            className="mySwiper clientSliderTwo"
                        >
                            <SwiperSlide className="item">
                                <div className="bg-wrapper">
                                    <img src="images/logo/logo-8.png" alt="" className="logo" />
                                    <p>Lorem ipsum dolor sit, consectetu qsu some adipiscing elit eiusmod temp incididu nt ut labore e dol magna great aliqua.mollit ani muim.</p>
                                    <div className="name font-rubik">Rashed Ka.</div>
                                    <div className="desig">Founder CreativeGigs</div>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide className="item">
                                <div className="bg-wrapper">
                                    <img src="images/logo/logo-9.png" alt="" className="logo" />
                                    <p>Lorem ipsum dolor sit, consectetu qsu some adipiscing elit eiusmod temp incididu nt ut labore e dol magna great aliqua.mollit ani muim.</p>
                                    <div className="name font-rubik">Zubayer Hasan.</div>
                                    <div className="desig">CEO & Founder Heloshape</div>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide className="item">
                                <div className="bg-wrapper">
                                    <img src="images/logo/logo-10.png" alt="" className="logo" />
                                    <p>Lorem ipsum dolor sit, consectetu qsu some adipiscing elit eiusmod temp incididu nt ut labore e dol magna great aliqua.mollit ani muim.</p>
                                    <div className="name font-rubik">Eh Jewel</div>
                                    <div className="desig">Senior Developer</div>
                                </div>
                            </SwiperSlide>
                            {/* Additional SwiperSlide components here */}
                        </Swiper>

                    </div> {/* /.slider-content */}
                </div> {/* /.client-feedback-slider-two */}



                {/*
			=====================================================
				Useable Tools
			=====================================================
			*/}
                <div className="useable-tools-section-three mt-200 mb-200 md-mt-100 md-mb-80">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6">
                                <div className="title-style-four">
                                    <h2>
                                        <span>Who is using ours <img src="images/shape/line-shape-10.svg" alt="" /></span>
                                        deski docs?
                                    </h2>
                                </div>
                                <p className="sub-text">Deski ties into your existing tools, services, & workflow. Get notifications or create story with others tools.</p>
                                <a href="#" className="all-button">See all partners <i className="flaticon-right-arrow"></i></a>
                            </div>
                        </div>
                    </div> {/* /.container */}
                    <div className="logo-wrapper d-flex flex-wrap justify-content-center align-items-center">
                        <div className="logo d-flex align-items-center justify-content-center"><img src="images/logo/logo-32.png" alt="" /></div>
                        <div className="logo d-flex align-items-center justify-content-center"><img src="images/logo/logo-33.png" alt="" /></div>
                        <div className="logo d-flex align-items-center justify-content-center"><img src="images/logo/logo-34.png" alt="" /></div>
                        <div className="logo d-flex align-items-center justify-content-center"><img src="images/logo/logo-35.png" alt="" /></div>
                        <div className="logo d-flex align-items-center justify-content-center"><img src="images/logo/logo-36.png" alt="" /></div>
                        <div className="logo d-flex align-items-center justify-content-center"><img src="images/logo/logo-37.png" alt="" /></div>
                        <div className="logo d-flex align-items-center justify-content-center"><img src="images/logo/logo-38.png" alt="" /></div>
                    </div> {/* /.logo-wrapper */}
                </div> {/* /.useable-tools-section-three */}


                {/*
			=====================================================
				Faq classNameic
			=====================================================
			*/}
                <div className="faq-classic with-bg">
                    <img src="images/shape/86.svg" alt="" className="shapes shape-one" />
                    <img src="images/shape/87.svg" alt="" className="shapes shape-two" />
                    <img src="images/shape/88.svg" alt="" className="shapes shape-three" />
                    <img src="images/shape/89.svg" alt="" className="shapes shape-four" />
                    <img src="images/shape/90.svg" alt="" className="shapes shape-five" />
                    <img src="images/shape/91.svg" alt="" className="shapes shape-six" />
                    <div className="container">
                        <div className="title-style-four text-center mb-100 md-mb-60">
                            <div className="row">
                                <div className="col-lg-7 col-md-9 m-auto">
                                    <h6>FAQ’s</h6>
                                    <h2>
                                        <span>Questions & Answers <img src="images/shape/line-shape-2.svg" alt="" /></span>
                                    </h2>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-lg-6">
                            {/* ================== FAQ Panel ================ */}
                            <div id="accordion">
                                <div className="card">
                                    <div className="card-header" id="headingOne">
                                        <h5 className="mb-0">
                                            <button className="btn btn-link" onClick={() => toggleCollapse('collapseOne')} aria-expanded={activeCollapse === 'collapseOne'}>
                                                How the affiliate program works?
                                            </button>
                                        </h5>
                                    </div>
                                    <div id="collapseOne" className={`collapse ${activeCollapse === 'collapseOne' ? 'show' : ''}`}>
                                        <div className="card-body">
                                            <p>mea case duis tollit et. Etiam nusquam set minium eu sea, ei tale paulo elab. Noluisse mnesarch Et is vero incorrupte eos deserunt quaeren.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="card">
                                    <div className="card-header" id="headingThree">
                                        <h5 className="mb-0">
                                            <button className="btn btn-link" onClick={() => toggleCollapse('collapseThree')} aria-expanded={activeCollapse === 'collapseThree'}>
                                                How delete my account?
                                            </button>
                                        </h5>
                                    </div>
                                    <div id="collapseThree" className={`collapse ${activeCollapse === 'collapseThree' ? 'show' : ''}`}>
                                        <div className="card-body">
                                            <p>mea case duis tollit et. Etiam nusquam set minium eu sea, ei tale paulo elab. Noluisse mnesarch Et is vero incorrupte eos deserunt quaeren.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="card">
                                    <div className="card-header" id="headingFour">
                                        <h5 className="mb-0">
                                            <button className="btn btn-link" onClick={() => toggleCollapse('collapseFour')} aria-expanded={activeCollapse === 'collapseFour'}>
                                                Ho to invite people with referral link?
                                            </button>
                                        </h5>
                                    </div>
                                    <div id="collapseFour" className={`collapse ${activeCollapse === 'collapseFour' ? 'show' : ''}`}>
                                        <div className="card-body">
                                            <p>mea case duis tollit et. Etiam nusquam set minium eu sea, ei tale paulo elab. Noluisse mnesarch Et is vero incorrupte eos deserunt quaeren.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="card">
                                    <div className="card-header" id="headingFive">
                                        <h5 className="mb-0">
                                            <button className="btn btn-link" onClick={() => toggleCollapse('collapseFive')} aria-expanded={activeCollapse === 'collapseFive'}>
                                                Is iOS app available for the iPhone?
                                            </button>
                                        </h5>
                                    </div>
                                    <div id="collapseFive" className={`collapse ${activeCollapse === 'collapseFive' ? 'show' : ''}`}>
                                        <div className="card-body">
                                            <p>mea case duis tollit et. Etiam nusquam set minium eu sea, ei tale paulo elab. Noluisse mnesarch Et is vero incorrupte eos deserunt quaeren.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-6">
                            {/* ================== FAQ Panel ================ */}
                            <div id="accordiontwo" className='faq-classic'>
                                <div className="card">
                                    <div className="card-header" id="headingOne2">
                                        <h5 className="mb-0">
                                            <button className="btn btn-link" onClick={() => toggleCollapse('collapseOne2')} aria-expanded={activeCollapse === 'collapseOne2'}>
                                                How the affiliate program works?
                                            </button>
                                        </h5>
                                    </div>
                                    <div id="collapseOne2" className={`collapse ${activeCollapse === 'collapseOne2' ? 'show' : ''}`}>
                                        <div className="card-body">
                                            <p>mea case duis tollit et. Etiam nusquam set minium eu sea, ei tale paulo elab. Noluisse mnesarch Et is vero incorrupte eos deserunt quaeren.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="card">
                                    <div className="card-header" id="headingTwo2">
                                        <h5 className="mb-0">
                                            <button className="btn btn-link" onClick={() => toggleCollapse('collapseTwo2')} aria-expanded={activeCollapse === 'collapseTwo2'}>
                                                How to create customer panel?
                                            </button>
                                        </h5>
                                    </div>
                                    <div id="collapseTwo2" className={`collapse ${activeCollapse === 'collapseTwo2' ? 'show' : ''}`}>
                                        <div className="card-body">
                                            <p>mea case duis tollit et. Etiam nusquam set minium eu sea, ei tale paulo elab. Noluisse mnesarch Et is vero incorrupte eos deserunt quaeren.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="card">
                                    <div className="card-header" id="headingThree2">
                                        <h5 className="mb-0">
                                            <button className="btn btn-link" onClick={() => toggleCollapse('collapseThree2')} aria-expanded={activeCollapse === 'collapseThree2'}>
                                                How delete my account?
                                            </button>
                                        </h5>
                                    </div>
                                    <div id="collapseThree2" className={`collapse ${activeCollapse === 'collapseThree2' ? 'show' : ''}`}>
                                        <div className="card-body">
                                            <p>mea case duis tollit et. Etiam nusquam set minium eu sea, ei tale paulo elab. Noluisse mnesarch Et is vero incorrupte eos deserunt quaeren.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="card">
                                    <div className="card-header" id="headingFour2">
                                        <h5 className="mb-0">
                                            <button className="btn btn-link" onClick={() => toggleCollapse('collapseFour2')} aria-expanded={activeCollapse === 'collapseFour2'}>
                                                How to invite people with referral link?
                                            </button>
                                        </h5>
                                    </div>
                                    <div id="collapseFour2" className={`collapse ${activeCollapse === 'collapseFour2' ? 'show' : ''}`}>
                                        <div className="card-body">
                                            <p>mea case duis tollit et. Etiam nusquam set minium eu sea, ei tale paulo elab. Noluisse mnesarch Et is vero incorrupte eos deserunt quaeren.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div> {/* /.faq-classic */}


            {/*
			=====================================================
				Fancy Short Banner Four
			=====================================================
			*/}
            <div className="fancy-short-banner-four">
                <div className="container">
                    <div className="bg-wrapper">
                        <div className="row align-items-center">
                            <div className="col-lg-6">
                                <div className="title">
                                    <h6>Subscribe Now</h6>
                                    <h2>Subscriber to our Newsletter</h2>
                                </div> {/* /.title-style-one */}
                            </div>
                            <div className="col-lg-6">
                                <div className="form-wrapper">
                                    <form action="#">
                                        <input type="text" placeholder="Email address" />
                                        <button>Subscribe</button>
                                    </form>
                                    <p className="font-rubik">Already a member? <a href="login.html">Sign in.</a></p>
                                </div> {/* /.form-wrapper */}
                            </div>
                        </div>
                    </div> {/* /.bg-wrapper */}
                </div> {/* /.container */}
            </div> {/* /.fancy-short-banner-four */}
        </div>
    )
}

export default Startup
