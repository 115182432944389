import React, { useState, useEffect } from 'react';

function Preloader() {
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        // Hide preloader after 2 seconds
        const timer = setTimeout(() => {
            setIsLoading(false);
        }, 2000);

        // Clean up the timer
        return () => clearTimeout(timer);
    }, []);

    return (
        <>
            {isLoading && (
                <div className="ctn-preloader">
                    <div className="animation-preloader">
                        <div className="spinner"></div>
                        <img src="/images/logo/B-of-BT1.ico" alt="logo" />
                        <div className="txt-loading">
                            <span data-text-preloader="B" className="letters-loading">B</span>
                            <span data-text-preloader="H" className="letters-loading">H</span>
                            <span data-text-preloader="A" className="letters-loading">A</span>
                            <span data-text-preloader="R" className="letters-loading">R</span>
                            <span data-text-preloader="A" className="letters-loading">A</span>
                            <span data-text-preloader="T" className="letters-loading">T</span>
                            <span data-text-preloader="T" className="letters-loading">T</span>
                            <span data-text-preloader="E" className="letters-loading">E</span>
                            <span data-text-preloader="C" className="letters-loading">C</span>
                            <span data-text-preloader="H" className="letters-loading">H</span>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
}

export default Preloader;
