import React, { useRef, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import { Autoplay, Pagination, Navigation } from 'swiper/modules';
import { Link } from 'react-router-dom';

function Home() {
    return (
        <div data-spy="scroll" data-target="#one-page-nav" data-offset="120">
            <div className="main-page-wrapper font-gordita">

                <div className="hero-banner-seven lg-container">
                    <div className="container">
                        <div className="illustration-container">
                            <img src="images/assets/ils_20.svg" alt="" />
                        </div>
                        <div className="row">
                            <div className="col-lg-6">
                                <h1 className="hero-heading"><span>Unlock</span> Ideas with  Power of Innovation</h1>
                                <p className="hero-sub-heading">Find Solutions with Bharattech's AI-Driven Ecosystem</p>
                                <form action="#">
                                    <input type="email" placeholder="ihidago@ujufidnan.gov" />
                                    <button>Subscribe</button>
                                </form>
                                <p className="term-text">For teams & individuals — web, mobile, Mac, Windows</p>
                                <div className="dropdown download-btn">
                                    <button className="dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                        Download
                                    </button>
                                    <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                        <a className="dropdown-item d-flex align-items-center" href="#">
                                            <img src="images/icon/103.svg" alt="" />
                                            <span>IOS & Android</span>
                                        </a>
                                        <a className="dropdown-item d-flex align-items-center" href="#">
                                            <img src="images/icon/104.svg" alt="" />
                                            <span>Mac & Windows</span>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="partner-slider-two mt-130 md-mt-80">
                        <div className="container">
                            <p className="text-center">__Supported By__</p>
                            <Swiper
                                spaceBetween={20}
                                centeredSlides={false}
                                slidesPerView={3}
                                loop={Infinity}
                                autoplay={{
                                    delay: 2500,
                                    disableOnInteraction: false,
                                }}
                                pagination={false}
                                navigation={false}
                                modules={[Autoplay, Pagination, Navigation]}
                                className="mySwiper partnerSliderTwo"
                                breakpoints={{
                                    576: {
                                        slidesPerView: 5,
                                        spaceBetween: 30,
                                    },
                                }}
                            >
                                <SwiperSlide className="item">
                                    <div className="img-meta d-flex align-items-center justify-content-center">
                                        <img src="images/logo/logo-21.png" alt="" />
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide className="item">
                                    <div className="img-meta d-flex align-items-center justify-content-center">
                                        <img src="images/logo/logo-22.png" alt="" />
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide className="item">
                                    <div className="img-meta d-flex align-items-center justify-content-center">
                                        <img src="images/logo/logo-23.png" alt="" />
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide className="item">
                                    <div className="img-meta d-flex align-items-center justify-content-center">
                                        <img src="images/logo/logo-24.png" alt="" />
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide className="item">
                                    <div className="img-meta d-flex align-items-center justify-content-center">
                                        <img src="images/logo/logo-25.png" alt="" />
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide className="item">
                                    <div className="img-meta d-flex align-items-center justify-content-center">
                                        <img src="images/logo/logo-26.png" alt="" />
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide className="item">
                                    <div className="img-meta d-flex align-items-center justify-content-center">
                                        <img src="images/logo/logo-27.png" alt="" />
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide className="item">
                                    <div className="img-meta d-flex align-items-center justify-content-center">
                                        <img src="images/logo/logo-28.png" alt="" />
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide className="item">
                                    <div className="img-meta d-flex align-items-center justify-content-center">
                                        <img src="images/logo/logo-29.png" alt="" />
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide className="item">
                                    <div className="img-meta d-flex align-items-center justify-content-center">
                                        <img src="images/logo/logo-30.png" alt="" />
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide className="item">
                                    <div className="img-meta d-flex align-items-center justify-content-center">
                                        <img src="images/logo/logo-31.png" alt="" />
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide className="item">
                                    <div className="img-meta d-flex align-items-center justify-content-center">
                                        <img src="images/logo/logo-32.png" alt="" />
                                    </div>
                                </SwiperSlide>
                            </Swiper>


                        </div>
                    </div>  {/*  /.partner-slider-two */}
                </div>  {/*  /.hero-banner-seven */}



                {/*  
			=============================================
				Fancy Feature Fifteen
			============================================== 
			*/}
                <div className="fancy-feature-fifteen mt-120 md-mt-80" id="product">
                    <div className="bg-wrapper">
                        <div className="container">
                            <div className="sldier-wrapper">
                                <div id="screenSlider" className="carousel slide" data-ride="carousel" data-interval="200000">
                                    <div className="row">
                                        <div className="col-lg-8 m-auto">
                                            <ol className="carousel-indicators justify-content-between">
                                                <li data-target="#screenSlider" data-slide-to="0" className="active">
                                                    <div className="d-flex align-items-center">
                                                        <img src="images/icon/90.svg" alt="" />
                                                        <p>Bharat AI</p>
                                                    </div>
                                                </li>
                                                <li data-target="#screenSlider" data-slide-to="1">
                                                    <div className="d-flex align-items-center">
                                                        <img src="images/icon/91.svg" alt="" />
                                                        <p>College Cue</p>
                                                    </div>
                                                </li>
                                                <li data-target="#screenSlider" data-slide-to="2">
                                                    <div className="d-flex align-items-center">
                                                        <img src="images/icon/92.svg" alt="" />
                                                        <p>Bsearch</p>
                                                    </div>
                                                </li>
                                                <li data-target="#screenSlider" data-slide-to="3">
                                                    <div className="d-flex align-items-center">
                                                        <img src="images/icon/92.svg" alt="" />
                                                        <p>Recag</p>
                                                    </div>
                                                </li>
                                            </ol>
                                        </div>
                                    </div>
                                    <div className="carousel-inner mt-60 sm-mt-40">
                                        <div className="carousel-item active">
                                            <img src="images/assets/screen_bharatai.png" alt="" className="m-auto" />
                                        </div>
                                        <div className="carousel-item">
                                            <img src="images/assets/screen_collegecue.png" alt="" className="m-auto" />
                                        </div>
                                        <div className="carousel-item">
                                            <img src="images/assets/screen_bsearch.png" alt="" className="m-auto" />
                                        </div>
                                        <div className="carousel-item">
                                            <img src="images/assets/screen_recag.png" alt="" className="m-auto" style={{ filter: 'blur(8px)' }} />
                                        </div>
                                    </div>
                                </div>
                            </div>  {/*  /.sldier-wrapper */}
                        </div>
                        <img src="images/shape/168.svg" alt="" className="shapes shape-one" />
                        <img src="images/shape/169.svg" alt="" className="shapes shape-two" />
                    </div>  {/*  /.bg-wrapper */}
                </div>  {/*  /.fancy-feature-fifteen */}



                {/*  
			=============================================
				Fancy Feature Sixteen
			============================================== 
			*/}
                <div className="fancy-feature-sixteen mt-200 md-mt-100" id="feature">
                    <div className="container">
                        <div className="block-style-eighteen">
                            <div className="row align-items-center">
                                <div className="col-lg-5" data-aos="fade-right" data-aos-duration="1200">
                                    <div className="text-wrapper">
                                        <h3 className="title">Transforming Indian <span>Technology</span></h3>
                                        <p>Bharattech is revolutionizing the Indian technology ecosystem by offering innovative solutions in artificial intelligence, data accessibility, & advanced computing. The company's flagship products, BharatAI, Recag, Collegecue, & Bsearch, prioritize privacy, efficiency, & sustainability, empowering students, startups, & businesses. Bharattech also supports the startup ecosystem through its Startup Support Program, offering incubation, mentorship, & funding to emerging ventures. This commitment to innovation & entrepreneurial environment is positioning Bharattech as a key player in driving India's technological growth. By continuously evolving its solutions, Bharattech is shaping the future of technology in India.</p>
                                    </div>  {/*  /.text-wrapper */}
                                </div>
                                <div className="col-lg-7" data-aos="fade-left" data-aos-duration="1200">
                                    <div className="screen-holder-one d-flex align-items-center justify-content-center">
                                        <div className="round-bg d-flex align-items-center justify-content-center" style={{ width: '193px', height: '193px' }}>
                                            <img src="images/logo/logo-26.png" alt="" />
                                        </div>
                                        <div className="round-bg d-flex align-items-center justify-content-center shapes logo-one" style={{ width: '65px', height: '65px' }}>
                                            <img src="images/logo/logo-27.png" alt="" />
                                        </div>
                                        <div className="round-bg d-flex align-items-center justify-content-center shapes logo-two" style={{ width: '105px', height: '105px' }}><img src="images/logo/logo-28.png" alt="" /></div>
                                        <div className="round-bg d-flex align-items-center justify-content-center shapes logo-three" style={{ width: '81px', height: '81px' }}>
                                            <img src="images/logo/logo-29.png" alt="" />
                                        </div>
                                        <div className="round-bg d-flex align-items-center justify-content-center shapes logo-four" style={{ width: '148px', height: '148px' }}>
                                            <img src="images/logo/logo-30.png" alt="" />
                                        </div>
                                        <div className="round-bg d-flex align-items-center justify-content-center shapes logo-five" style={{ width: '105px', height: '105px' }}>
                                            <img src="images/logo/logo-31.png" alt="" />
                                        </div>
                                        <img src="images/shape/170.svg" alt="" className="shapes shape-one" />
                                    </div>
                                </div>
                            </div>
                        </div>  {/*  /.block-style-eighteen */}

                        <div className="block-style-eighteen mt-200 pt-50 md-mt-80">
                            <div className="row align-items-center">
                                <div className="col-lg-5 order-lg-last" data-aos="fade-left" data-aos-duration="1200">
                                    <div className="text-wrapper">
                                        <h3 className="title">Empowering India with Tailored <br /> Technology <span>Solution</span></h3>
                                        <p>Bharattech is a leading Indian technology company that offers products tailored to the unique needs of Indian users. BharatAI, a Large Language Model (LLM), is designed to understand the nuances of Indian languages, culture, & context, providing personalized, accurate solutions. Recag, Bharattech's GPU rental service, addresses the high cost of computing resources for Indian AI enthusiasts, researchers, & startups. Collegecue, Bharattech's platform for students, offers a complete solution for education & skill-building, connecting students with real-world opportunities & industry experts. Bsearch, an AI-enabled hyper-local search engine, is currently in its testing phase, providing highly relevant, localized search results. These products are transforming how Indians interact with technology, making AI, education, data accessibility, & local search capabilities more inclusive, affordable, & efficient while maintaining the highest standards of privacy & security.</p>
                                    </div>  {/*  /.text-wrapper */}
                                </div>
                                <div className="col-lg-7 order-lg-first" data-aos="fade-right" data-aos-duration="1200">
                                    <div className="screen-holder-two">
                                        <img src="images/assets/screen_12.png" alt="" />
                                        <img src="images/assets/screen_13.png" alt="" className="shapes screen-one" />
                                    </div>
                                </div>
                            </div>
                        </div>  {/*  /.block-style-eighteen */}

                        <div className="block-style-eighteen mt-200 pt-50 md-mt-80">
                            <div className="row align-items-center">
                                <div className="col-lg-5" data-aos="fade-right" data-aos-duration="1200">
                                    <div className="text-wrapper">
                                        <h3 className="title"><span>Innovative</span> Solutions for Growth & Success</h3>
                                        <p>Bharattech's products cater to India's growing startup community by offering affordable, scalable, & cost-effective solutions. BharatAI, a large language model, provides AI-driven solutions for Indian businesses, while Recag offers a GPU rental program for startups. Collegecue connects startups with skilled students & graduates, while Bsearch improves local visibility for SMEs. These products help startups innovate, scale, & succeed in a competitive market, contributing to India's entrepreneurial ecosystem.</p>
                                    </div>  {/*  /.text-wrapper */}
                                </div>
                                <div className="col-lg-7" data-aos="fade-left" data-aos-duration="1200">
                                    <div className="screen-holder-three d-flex align-items-center justify-content-center">
                                        <img src="images/assets/screen_14.png" alt="" />
                                    </div>
                                </div>
                            </div>
                        </div>  {/*  /.block-style-eighteen */}
                    </div>
                </div>  {/*  /.fancy-feature-sixteen */}



                {/* 
			=====================================================
				Fancy Text Block Twenty Two
			=====================================================
			*/}
                <div className="fancy-text-block-twentyTwo lg-container mt-250 md-mt-120" id="whyUs">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-xl-5 col-lg-6 order-lg-last" data-aos="fade-left" data-aos-duration="1200">
                                <div className="text-wrapper">
                                    {/* <a className="fancybox mb-20 md-mb-10" data-fancybox="" href="https://www.youtube.com/embed/aXFSJTjVjw0">
                                        <img src="images/icon/71.svg" alt="" className="icon" />
                                    </a> */}
                                    <div className="title-style-seven">
                                        <h2 style={{ color: '#000' }}>Join <span>Bharattech</span> to Drive Social Change</h2>
                                    </div>
                                    <p>Bharattech is creating a thriving network for entrepreneurs, tech enthusiasts, and social change agents who are driven to improve society. This community enables everyone to work together, share knowledge, and make significant contributions to the development of technology and social innovation by bringing like-minded people together. We can influence change and create a brighter future for India by working together.</p>
                                    {/* <a href="#" className="theme-btn-eight">Learn more</a> */}
                                </div>  {/*  /.text-wrapper */}
                            </div>
                            <div className="col-xl-7 col-lg-6 col-md-8 m-auto order-lg-first" data-aos="fade-right" data-aos-duration="1200">
                                <div className="illustration-holder">
                                    <img src="images/assets/ils_21.svg" alt="" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>  {/*  /.fancy-text-block-twentyTwo */}



                {/* 
			=====================================================
				Fancy Feature Seventeen
			=====================================================
			*/}
                <div className="fancy-feature-seventeen mt-150 md-mt-90" id="product">
                    <div className="bg-wrapper">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-4 col-sm-6" data-aos="fade-up" data-aos-duration="1200">
                                    <div className="block-meta">
                                        <div className="icon d-flex align-items-end"><img src="images/icon/94.svg" alt="" /></div>
                                        <h4>Technological Innovation</h4>
                                        <p>Bharattech drives cutting-edge solutions in AI, data science, & education, positioning itself at the forefront of technological advancements in India.</p>
                                    </div>  {/*  /.block-meta */}
                                </div>
                                <div className="col-lg-4 col-sm-6" data-aos="fade-up" data-aos-duration="1200" data-aos-delay="100">
                                    <div className="block-meta">
                                        <div className="icon d-flex align-items-end"><img src="images/icon/95.svg" alt="" /></div>
                                        <h4>Customized AI Solutions</h4>
                                        <p>Bharattech’s AI products, like BharatAI, are tailored specifically for Indian industries, offering solutions that prioritize privacy, security, & efficiency.</p>
                                    </div>  {/*  /.block-meta */}
                                </div>
                                <div className="col-lg-4 col-sm-6" data-aos="fade-up" data-aos-duration="1200" data-aos-delay="200">
                                    <div className="block-meta">
                                        <div className="icon d-flex align-items-end"><img src="images/icon/96.svg" alt="" /></div>
                                        <h4>Commitment to Sustainability</h4>
                                        <p>Through optimized resource use & eco-friendly practices, Bharattech is dedicated to reducing environmental impact while fostering technological growth.</p>
                                    </div>  {/*  /.block-meta */}
                                </div>
                                <div className="col-lg-4 col-sm-6" data-aos="fade-up" data-aos-duration="1200">
                                    <div className="block-meta">
                                        <div className="icon d-flex align-items-end"><img src="images/icon/97.svg" alt="" /></div>
                                        <h4>Support for Indian Startups</h4>
                                        <p>With its Startup Support Program, Bharattech nurtures entrepreneurial ventures by providing incubation, funding, & growth strategies to foster innovation.</p>
                                    </div>  {/*  /.block-meta */}
                                </div>
                                <div className="col-lg-4 col-sm-6" data-aos="fade-up" data-aos-duration="1200" data-aos-delay="100">
                                    <div className="block-meta">
                                        <div className="icon d-flex align-items-end"><img src="images/icon/98.svg" alt="" /></div>
                                        <h4>Support for Indian Startups</h4>
                                        <p>Bharattech bridges the gap between industry needs & academic talent by offering specialized courses, resources, & tools for students, educators, & professionals.</p>
                                    </div>  {/*  /.block-meta */}
                                </div>
                                <div className="col-lg-4 col-sm-6" data-aos="fade-up" data-aos-duration="1200" data-aos-delay="200">
                                    <div className="block-meta">
                                        <div className="icon d-flex align-items-end"><img src="images/icon/99.svg" alt="" /></div>
                                        <h4>Collaborative Ecosystem</h4>
                                        <p>Bharattech fosters partnerships with educational institutions, industry leaders, & government bodies, creating a thriving ecosystem for innovation & progress.</p>
                                    </div>  {/*  /.block-meta */}
                                </div>
                            </div>
                        </div>
                    </div>  {/*  /.bg-wrapper */}
                </div>  {/*  /.fancy-feature-seventeen */}


                {/* 
			=====================================================
				Client Feedback Slider Six
			=====================================================
			*/}
                <div className="client-feedback-slider-six mt-250 md-mt-120" id="feedback">
                    <div className="inner-container">
                        <div className="container">
                            <div className="row">
                                <div className="col-12">
                                    <img src="images/icon/100.svg" alt="" className="m-auto" />
                                    <div className="title-style-seven text-center mt-20">
                                        <h2><span>Client</span> love us & we love them</h2>
                                        <p>Kind words from our BharatTech Clients.</p>
                                    </div>  {/*  /.title-style-six */}
                                </div>
                            </div>
                        </div>
                        <Swiper
                            spaceBetween={25}
                            slidesPerView={3}
                            loop={Infinity}
                            autoplay={{
                                delay: 2500,
                                disableOnInteraction: false,
                            }}
                            pagination={{
                                clickable: true,
                            }}
                            modules={[Autoplay, Pagination]}
                            className="mySwiper clientSliderSix row"
                        >
                            <SwiperSlide className="item">
                                <div className="feedback-wrapper">
                                    <p>Already closed 1 deal from Accelerator & in the process of closing 2 more now. Have “a few others” that will be closing in the near future!</p>
                                    <div className="d-flex align-items-center">
                                        <img src="images/media/img_78.png" alt="" className="avatar" />
                                        <h6 className="name">Martin Jonas, <span>USA</span></h6>
                                    </div>
                                </div>  {/*  /.feedback-wrapper */}
                            </SwiperSlide>
                            <SwiperSlide className="item">
                                <div className="feedback-wrapper">
                                    <p>Already closed 1 deal from Accelerator & in the process of closing 2 more now. Have “a few others” that will be closing in the near future!</p>
                                    <div className="d-flex align-items-center">
                                        <img src="images/media/img_79.png" alt="" className="avatar" />
                                        <h6 className="name">Elias Brett, <span>USA</span></h6>
                                    </div>
                                </div>  {/*  /.feedback-wrapper */}
                            </SwiperSlide>
                            <SwiperSlide className="item">
                                <div className="feedback-wrapper">
                                    <p>Already closed 1 deal from Accelerator & in the process of closing 2 more now. Have “a few others” that will be closing in the near future!</p>
                                    <div className="d-flex align-items-center">
                                        <img src="images/media/img_80.png" alt="" className="avatar" />
                                        <h6 className="name">Rashed Ka, <span>Spain</span></h6>
                                    </div>
                                </div>  {/*  /.feedback-wrapper */}
                            </SwiperSlide>
                            <SwiperSlide className="item">
                                <div className="feedback-wrapper">
                                    <p>Already closed 1 deal from Accelerator & in the process of closing 2 more now. Have “a few others” that will be closing in the near future!</p>
                                    <div className="d-flex align-items-center">
                                        <img src="images/media/img_78.png" alt="" className="avatar" />
                                        <h6 className="name">Martin Jonas, <span>USA</span></h6>
                                    </div>
                                </div>  {/*  /.feedback-wrapper */}
                            </SwiperSlide>
                        </Swiper>
                    </div>  {/*  /.inner-container */}
                </div>  {/*  /.client-feedback-slider-six */}


                {/* 
			=====================================================
				Fancy Short Banner Eight
			=====================================================
			*/}
                <div className="fancy-short-banner-eight mt-170 md-mt-80">
                    <div className="container">
                        <div className="row">
                            <div className="col-xl-8 col-lg-11 m-auto" data-aos="fade-up" data-aos-duration="1200">
                                <div className="title-style-seven text-center">
                                    <h2>"Empowering <span>India's</span> Future with Innovation, Sustainability, & Collaboration"</h2>
                                </div>  {/*  /.title-style-six */}
                            </div>
                        </div>
                        <div data-aos="fade-up" data-aos-duration="1200" data-aos-delay="150">
                            <div className="download-btn">
                                <button type="button">
                                    Contact Us
                                </button>
                                {/* <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                    <a className="dropdown-item d-flex align-items-center" href="#">
                                        <img src="images/icon/103.svg" alt="" />
                                        <span>IOS & Android</span>
                                    </a>
                                    <a className="dropdown-item d-flex align-items-center" href="#">
                                        <img src="images/icon/104.svg" alt="" />
                                        <span>Mac & Windows</span>
                                    </a>
                                </div> */}
                            </div>
                        </div>
                    </div>  {/*  /.container */}
                    <img src="images/shape/171.svg" alt="" className="shapes shape-one" />
                    <img src="images/shape/172.svg" alt="" className="shapes shape-two" />
                </div>  {/*  /.fancy-short-banner-eight */}
            </div>
        </div>
    )
}

export default Home;