import React, { useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';

// Use public path for images
const icon44 = '/images/icon/44.svg';
const icon45 = '/images/icon/45.svg';
const icon46 = '/images/icon/46.svg';
const shape64 = '/images/shape/64.svg';

function Contact() {
	useEffect(() => {
		// Initialize AOS animations on component mount
		AOS.init({ duration: 1200 });
	}, []);

	return (
		<div>
			<div className="main-page-wrapper p0">

				<div className="fancy-hero-four bg-doc space-fix">
					<div className="bg-wrapper">
						<div className="container">
							<div className="row">
								<div className="col-xl-9 col-lg-11 col-md-10 m-auto">
									<h6>Contact us</h6>
									<h2>Feel free to contact us or just say hi!</h2>
								</div>
							</div>
						</div>
					</div> {/* /.bg-wrapper */}
				</div> {/* /.fancy-hero-four */}

				{/* Contact Style Two */}
				<div className="contact-style-two">
					<div className="container">
						<div className="contact-info-wrapper">
							<div className="row justify-content-center">
								<div className="col-lg-4 col-sm-6 d-lg-flex">
									<div className="address-info">
										<div className="icon d-flex align-items-end">
											<img src={icon44} alt="Location Icon" />
										</div>
										<div className="title">Location</div>
										<p className="font-rubik">Kanpur, Uttar Pradesh, India</p>
									</div> {/* /.address-info */}
								</div>
								<div className="col-lg-4 col-sm-6 d-lg-flex">
									<div className="address-info">
										<div className="icon d-flex align-items-end">
											<img src={icon45} alt="Contact Icon" />
										</div>
										<div className="title">Contact</div>
										<p className="font-rubik">Info@bharat-tech.org <br />
											Contact@bharat-tech.org</p>
									</div> {/* /.address-info */}
								</div>
								<div className="col-lg-4 col-sm-6 d-lg-flex">
									<div className="address-info">
										<div className="icon d-flex align-items-end">
											<img src={icon46} alt="Social Media Icon" />
										</div>
										<div className="title">Social Media</div>
										<p className="font-rubik">Find on social media</p>
										<ul className="d-flex justify-content-center">
											<li><a href="https://www.facebook.com/Bharattech.org/"><i className="fa fa-facebook" aria-hidden="true"></i></a></li>
											<li><a href="https://twitter.com/bharattech_in"><i className="fa fa-twitter" aria-hidden="true"></i></a></li>
											<li><a href="https://in.linkedin.com/company/bharattech"><i className="fa fa-linkedin" aria-hidden="true"></i></a></li>
										</ul>
									</div> {/* /.address-info */}
								</div>
							</div>
							<img src={shape64} alt="Decorative Shape" className="shapes shape-one" />
						</div> {/* /.contact-info-wrapper */}

						<div className="form-style-classNameic mt-150 md-mt-80">
							<form action="#" id="contact-form" data-toggle="validator">
								<div className="messages"></div>
								<div className="row controls">
									<div className="col-md-6" data-aos="fade-right" data-aos-duration="1200">
										<div className="input-group-meta form-group mb-60">
											<label>First Name</label>
											<input type="text" placeholder="Michel" name="Fname" required="required" data-error="Name is required." />
											<div className="help-block with-errors"></div>
										</div>
									</div>
									<div className="col-md-6" data-aos="fade-left" data-aos-duration="1200">
										<div className="input-group-meta form-group mb-60">
											<label>Last Name</label>
											<input type="text" placeholder="Hawkins" name="Lname" required="required" data-error="Name is required." />
											<div className="help-block with-errors"></div>
										</div>
									</div>
									<div className="col-12" data-aos="fade-up" data-aos-duration="1200">
										<div className="input-group-meta form-group mb-60">
											<label>Email Address</label>
											<input type="email" placeholder="saeslal@zouj.co.uk" name="email" required="required" data-error="Valid email is required." />
											<div className="help-block with-errors"></div>
										</div>
									</div>
									<div className="col-12" data-aos="fade-up" data-aos-duration="1200">
										<div className="input-group-meta form-group lg mb-40">
											<label>Your Message</label>
											<textarea placeholder="your message here.." name="message" required="required" data-error="Please, leave us a message."></textarea>
											<div className="help-block with-errors"></div>
										</div>
									</div>
									<div className="col-12" data-aos="fade-up" data-aos-duration="1200">
										<button className="theme-btn-six lg">Send Message</button>
									</div>
								</div>
							</form>
						</div> {/* /.form-style-classNameic */}
					</div>
				</div> {/* /.contact-style-two */}

				{/* Fancy Short Banner Four */}
				<div className="fancy-short-banner-four m0 pt-150 md-pt-100">
					<div className="container">
						<div className="bg-wrapper">
							<div className="row align-items-center">
								<div className="col-lg-6">
									<div className="title">
										<h6>Subscribe Now</h6>
										<h2>Subscriber to our Newsletter</h2>
									</div> {/* /.title-style-one */}
								</div>
								<div className="col-lg-6">
									<div className="form-wrapper">
										<form action="#">
											<input type="text" placeholder="Email address" />
											<button>Subscribe</button>
										</form>
										<p className="font-rubik">Already a member? <a href="login.html">Sign in.</a></p>
									</div> {/* /.form-wrapper */}
								</div>
							</div>
						</div> {/* /.bg-wrapper */}
					</div> {/* /.container */}
				</div> {/* /.fancy-short-banner-four */}
			</div>
		</div>
	);
}

export default Contact;
