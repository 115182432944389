import React, { useState } from 'react';
import { Link } from 'react-router-dom';

function Navbar() {
    const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

    // Toggle mobile menu
    const handleToggle = () => {
        setIsMobileMenuOpen(!isMobileMenuOpen);
    };


    const overlayStyle = {
        position: 'fixed',
        top: 130,
        left: 0,
        width: '100%',
        height: '100%',
        backgroundColor: 'rgba(0, 0, 0, 0.5)', // Dark overlay
        zIndex: 0,
        display: isMobileMenuOpen ? 'block' : 'none',
    };

    return (
        <div className="theme-main-menu sticky-menu theme-menu-five">
            <div className="nav-items">
                {/* Logo */}
                <div className="logo1">
                    <Link to="/">
                        <img src="images/logo/header-logo.png" alt="Logo" height={90} width={220} />
                    </Link>
                </div>

                {/* Navigation Menu */}
                <nav id="mega-menu-holder" className="navbar navbar-expand-lg">
                    <div className={`nav-container ${isMobileMenuOpen ? 'menu-open' : ''}`}>
                        <div className={`navbar-collapse collapse ${isMobileMenuOpen ? 'show' : ''}`}>
                            <div className="d-lg-flex justify-content-center align-items-center">
                                <ul
                                    className={`navbar-nav main-side-nav font-gordita ${isMobileMenuOpen ? 'mobile-menu' : ''}`}
                                    id="one-page-nav"
                                >
                                    <li className="nav-item">
                                        <Link to="/services" className="nav-link">
                                            Services
                                        </Link>
                                    </li>
                                    {/* <li className="nav-item">
                                        <Link to="/startup" className="nav-link">
                                            Startups
                                        </Link>
                                    </li> */}
                                    <li className="nav-item">
                                        <Link to="/team" className="nav-link">
                                            Team
                                        </Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link to="/about" className="nav-link">
                                            About Us
                                        </Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link to="/contact" className="nav-link">
                                            Contact Us
                                        </Link>
                                    </li>

                                    {/* Emp. Login inside the menu for mobile */}
                                    <li className="nav-item d-lg-none"> {/* Only visible on mobile */}
                                        <div className="login-btn">
                                            <a
                                                href="https://employee.bharat-tech.org"
                                                className="login-link"
                                                target="_blank"
                                                rel="noopener noreferrer"
                                            >
                                                Emp. Login
                                            </a>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </nav>

                {/* Mobile Menu Toggle Button (Only visible on mobile, placed on the right) */}
                <button className="navbar-toggler d-lg-none ml-auto" onClick={handleToggle}>
                    <span></span>
                    <span></span>
                    <span></span>
                </button>

                {/* Emp. Login button visible on desktop */}
                <div className="d-none d-lg-block">
                    <a
                        href="https://employee.bharat-tech.org"
                        className="login-link"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        Emp. Login
                    </a>
                </div>
            </div>

            {/* Overlay for darkening background when mobile menu is open */}
            {isMobileMenuOpen && <div className="overlay" style={overlayStyle} onClick={handleToggle}></div>}
        </div>
    );
}

export default Navbar;
