import React, { useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';

// Import images dynamically (adjust paths as needed)
const logoBharatAI = '/images/logo/logo-bharatai.png';
const logoCollegecue = '/images/logo/logo-collegecue.png';
const logoBsearch = '/images/logo/logo-bsearch.png';
const logoRecag = '/images/logo/logo-recag.png';
const icon184 = '/images/icon/184.svg';
const img108 = '/images/media/img_108.png';
const icon185 = '/images/icon/185.png';
const shape29 = '/images/shape/29.svg';
const shape30 = '/images/shape/30.svg';
const shape31 = '/images/shape/31.svg';
const shape32 = '/images/shape/32.svg';
const shape33 = '/images/shape/33.svg';
const shape210 = '/images/shape/210.svg';
const shape211 = '/images/shape/211.svg';

function Services() {
	useEffect(() => {
		// Initialize AOS animations on component mount
		AOS.init({ duration: 1200 });
	}, []);

	const handleNavigation = () => {
		// Programmatically navigate to the service details page
		window.location.href = '/service-detailsV1';
	};

	return (
		<div className="main-page-wrapper">
			<div className="fancy-hero-one">
				<div className="container">
					<div className="row">
						<div className="col-xl-10 m-auto">
							<h2 className="font-rubik">Our Services</h2>
						</div>
						<div className="col-xl-9 m-auto">
							<p className="font-rubik">
								We are a creative company that focuses on establishing long-term relationships with customers.
							</p>
						</div>
					</div>
				</div>
				{/* Bubble elements */}
				<div className="bubble-one"></div>
				<div className="bubble-two"></div>
				<div className="bubble-three"></div>
				<div className="bubble-four"></div>
				<div className="bubble-five"></div>
				<div className="bubble-six"></div>
			</div>

			{/* Fancy Feature Thirty Two */}
			<div className="fancy-feature-thirtyTwo pt-110 pb-160 md-pt-90 md-pb-100">
				<div className="container">
					<div className="row align-items-center justify-content-center">
						<div className="col-lg-4 col-md-6" data-aos="fade-up">
							<div className="title-style-eleven">
								<h2>Explore our service.</h2>
							</div>
						</div>
						<div className="col-lg-4 col-md-6" data-aos="fade-up" data-aos-delay="100">
							<div className="block-style-twentyTwo" onClick={handleNavigation}>
								<div className="front">
									<div className="services-logo d-flex align-items-center justify-content-center" style={{ background: '#F7EDFF' }}>
										<img src={logoBharatAI} alt="BharatAI" />
									</div>
								</div>
								<div className="back">
									<p>BharatAI is a sophisticated, privacy-conscious large language model designed for Indian users, offering exceptional natural language understanding, context-aware responses, and adaptability across various applications.</p>
								</div>
							</div>
						</div>

						<div className="col-lg-4 col-md-6" data-aos="fade-up" data-aos-delay="200">
							<div className="block-style-twentyTwo" onClick={handleNavigation}>
								<div className="front">
									<div className="services-logo d-flex align-items-center justify-content-center" style={{ background: '#FFF8E0' }}>
										<img src={logoCollegecue} alt="Collegecue" />
									</div>
								</div>
								<div className="back">
									<p>Collegecue is a platform that enhances education by providing interactive courses, study materials, and career guidance tools, bridging educational gaps and empowering users.</p>
								</div>
							</div>
						</div>

						<div className="col-lg-4 col-md-6"></div>

						<div className="col-lg-4 col-md-6" data-aos="fade-up">
							<div className="block-style-twentyTwo" onClick={handleNavigation}>
								<div className="front">
									<div className="services-logo d-flex align-items-center justify-content-center" style={{ background: '#FFEBDB' }}>
										<img src={logoBsearch} alt="Bsearch" />
									</div>
								</div>
								<div className="back">
									<p>Bsearch, an AI-powered search engine, uses QRADE technology to deliver precise, localized results, improving search accuracy and user experience in its testing phase.</p>
								</div>
							</div>
						</div>

						<div className="col-lg-4 col-md-6" data-aos="fade-up" data-aos-delay="100">
							<div className="block-style-twentyTwo" onClick={handleNavigation}>
								<div className="front">
									<div className="services-logo d-flex align-items-center justify-content-center" style={{ background: '#E0F8F8' }}>
										<img src={logoRecag} alt="Recag" />
									</div>
								</div>
								<div className="back">
									<p>Recag is a platform revolutionizing India's AI landscape, offering GPU rental, extensive datasets, and AI model blueprints, promoting sustainability, accessibility, and innovation.</p>
								</div>
							</div>
						</div>

					</div>
				</div>
			</div>
			<div className="fancy-short-banner-four m0 pt-150 md-pt-100">
				<div className="container">
					<div className="bg-wrapper">
						<div className="row align-items-center">
							<div className="col-lg-6">
								<div className="title">
									<h6>Subscribe Now</h6>
									<h2>Subscriber to our Newsletter</h2>
								</div> {/* /.title-style-one */}
							</div>
							<div className="col-lg-6">
								<div className="form-wrapper">
									<form action="#">
										<input type="text" placeholder="Email address" />
										<button>Subscribe</button>
									</form>
									<p className="font-rubik">Already a member? <a href="login.html">Sign in.</a></p>
								</div> {/* /.form-wrapper */}
							</div>
						</div>
					</div> {/* /.bg-wrapper */}
				</div> {/* /.container */}
			</div> {/* /.fancy-short-banner-four */}
		</div>
	);
}

export default Services;
