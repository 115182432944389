import React, { useRef } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import 'swiper/css/scrollbar';
import { Pagination, Navigation } from 'swiper/modules';

function About() {
	const swiperRef = useRef(null);
	const goToPrevSlide = () => {
		if (swiperRef.current) {
			swiperRef.current.swiper.slidePrev();
		}
	};
	// Function to navigate to the next slide
	const goToNextSlide = () => {
		if (swiperRef.current) {
			swiperRef.current.swiper.slideNext();
		}
	};
	return (
		<>
			<div className="main-page-wrapper p0">
				<img src="images/assets/ils_13.svg" alt="" className="illustration" data-aos="fade-up" data-aos-duration="1200" />

				<div className="fancy-hero-five">
					<img src="images/shape/93.svg" alt="" className="shapes shape-one" />
					<img src="images/shape/94.svg" alt="" className="shapes shape-two" />
					<div className="bg-wrapper">
						<div className="container">
							<div className="row">
								<div className="col-lg-7 col-md-8 col-sm-10">
									<h1 className="heading">
										<span>Leading India's Deep-Tech <img src="images/shape/line-shape-11.svg" alt="" /></span>Revolution
									</h1>
									<p className="sub-heading">Bharattech Techecosystem Pvt. Ltd. is at the forefront of India's deep-tech innovation, offering cutting-edge solutions that cater specifically to the needs of the nation. Our flagship products—BharatAI, CollegeCue, Recag, & Bsearch—are transforming industries by making technology accessible, affordable, & impactful for businesses, startups, & individuals across India. From simplifying AI integration to fostering tech-driven learning, Bharattech's innovations are driving meaningful change in how people leverage technology for progress.</p>
								</div>
								<div className="col-lg-5 col-md-4">
									<img src="images/media/img_52.png" alt="" className="img-meta" />
								</div>
							</div>
						</div>
					</div> {/* /.bg-wrapper */}
				</div> {/* /.fancy-hero-five */}



				{/* 
			=============================================
				Fancy Text block Seventeen
			============================================== 
			*/}
				<div className="fancy-text-block-seventeen mt-250 pt-20 md-mt-80">
					<div className="container">
						<div className="row">
							<div className="col-lg-5 ml-auto order-lg-last" data-aos="fade-left" data-aos-duration="1200">
								<div className="title-style-four">
									<div className="row">
										<div className="col-lg-12 col-md-8">
											<h2>Building a Thriving
												<span> Tech Community!<img src="images/shape/shape-line-2.svg" alt="" /></span>
											</h2>
										</div>
									</div>
								</div>
							</div>
							<div className="col-xl-6 col-lg-7 order-lg-first" data-aos="fade-right" data-aos-duration="1200">
								<p className="text-meta">At the heart of Bharattech is a vision to create an inclusive & supportive tech ecosystem. By empowering startups, fostering tech enthusiasts, & nurturing innovators, we are building a vibrant community where groundbreaking ideas are brought to life.Through collaborations with educational institutions, government bodies, & industry leaders, Bharattech is dedicated to advancing technological growth that aligns with India's developmental goals, positioning the company as a key player in shaping the future of technology in the country.</p>
							</div>
						</div>
					</div>
				</div> {/* /.fancy-text-block-seventeen */}


				{/* awards */}
				<div className='awards'>
					<div className='head'>
						<h2><span>AWARDS<img src="images/shape/line-shape-2.svg" alt="" width={260} /></span></h2>
						<p>
							BharatTech is setting up the legacy for the tech startups in different forums
						</p>
					</div>
					<div className='award-container'>
						<div className='award-card'>
							<img src="images/logo/award-logo.png" alt=''></img>
							<div className='award-content'>
								<h3 className='year'>2023</h3>
								<div className='title'>UTTAR PRADESH GLOBAL INVESTOR SUMMIT</div>
								<p>Launching of BSearch by PM Shri Narendra Modi & credited among top tech-startups in the state.</p>
							</div>
						</div>
						<div className='award-card'>
							<img src="images/logo/award-logo.png" alt=''></img>
							<div className='award-content'>
								<h3 className='year'>2023</h3>
								<div className='title'>INTERNATIONAL TRADE SHOW</div>
								<p>Representation of Bharattech at UP International Trade Show 2023</p>
							</div>
						</div>
						<div className='award-card'>
							<img src="images/logo/award-logo.png" alt=''></img>
							<div className='award-content'>
								<h3 className='year'>2024</h3>
								<div className='title'>INDIAN WEB BROWSER DEVELOPMENT CHALLENGE</div>
								<p>Participation of Bharattech at IWBDC</p>
							</div>
						</div>
						<div className='award-card'>
							<img src="images/logo/award-logo.png" alt=''></img>
							<div className='award-content'>
								<h3 className='year'>2024</h3>
								<div className='title'>MEITY</div>
								<p>Participation of Bharattech at MEITY Open Challenge Program</p>
							</div>
						</div>
					</div>
				</div>


				{/*
			=====================================================
				Useable Tools
			=====================================================
			*/}
				{/* <div className="useable-tools-section bg-transparent mt-140 md-mt-80">
					<div className="container">
						<div className="title-style-three text-center mb-60 md-mb-50 sm-mb-30">
							<h6>Our Partners</h6>
							<h2>
								Who is
								<span> using deski <img src="images/shape/line-shape-2.svg" alt="" /></span>
								docs?
							</h2>
						</div>
						<div className="row">
							<div className="col-xl-8 col-lg-9 m-auto">
								<p className="sub-text pb-80 md-pb-50">Deski ties into your existing tools, services, & workflow. Get notifications or create story with others tools.</p>
							</div>
						</div>

						<div className="row">
							<div className="col-lg-3 col-md-4 col-6" data-aos="fade-up" data-aos-duration="1200">
								<div className="img-box bx-a">
									<a href="#"><img src="images/logo/p-1.png" alt="" /></a>
								</div>
							</div>
							<div className="col-xl-2 col-lg-3 col-md-4 col-6" data-aos="fade-up" data-aos-duration="1200" data-aos-delay="50">
								<div className="img-box bx-b">
									<a href="#"><img src="images/logo/p-2.png" alt="" /></a>
								</div>
							</div>
							<div className="col-lg-3 col-md-4 col-6" data-aos="fade-up" data-aos-duration="1200" data-aos-delay="100">
								<div className="img-box bx-c">
									<a href="#"><img src="images/logo/p-3.png" alt="" /></a>
								</div>
							</div>
							<div className="col-xl-2 col-lg-3 col-md-4 col-6" data-aos="fade-up" data-aos-duration="1200" data-aos-delay="150">
								<div className="img-box bx-d">
									<a href="#"><img src="images/logo/p-4.png" alt="" /></a>
								</div>
							</div>
							<div className="col-xl-2 col-lg-3 col-md-4 col-6" data-aos="fade-up" data-aos-duration="1200" data-aos-delay="200">
								<div className="img-box bx-e">
									<a href="#"><img src="images/logo/p-5.png" alt="" /></a>
								</div>
							</div>
							<div className="col-xl-4 col-lg-2 col-md-4 col-6" data-aos="fade-up" data-aos-duration="1200" data-aos-delay="250">
								<div className="img-box bx-f">
									<a href="#"><img src="images/logo/p-6.png" alt="" /></a>
								</div>
							</div>
							<div className="col-xl-2 col-lg-3 col-md-4 col-6" data-aos="fade-up" data-aos-duration="1200" data-aos-delay="300">
								<div className="img-box bx-g">
									<a href="#"><img src="images/logo/p-7.png" alt="" /></a>
								</div>
							</div>
							<div className="col-xl-3 col-lg-3 col-md-4 col-6" data-aos="fade-up" data-aos-duration="1200" data-aos-delay="350">
								<div className="img-box bx-h">
									<a href="#"><img src="images/logo/p-8.png" alt="" /></a>
								</div>
							</div>
							<div className="col-xl-3 col-lg-12 col-md-4 col-6 m-auto" data-aos="fade-up" data-aos-duration="1200" data-aos-delay="400">
								<div className="img-box bx-i">
									<a href="#"><img src="images/logo/p-9.png" alt="" /></a>
								</div>
							</div>
						</div>
					</div>
				</div> */}




				{/*
			=====================================================
				Fancy Short Banner Four
			=====================================================
			*/}
				<div className="fancy-short-banner-four">
					<div className="container">
						<div className="bg-wrapper">
							<div className="row align-items-center">
								<div className="col-lg-6">
									<div className="title">
										<h6>Subscribe Now</h6>
										<h2>Subscribe to our Newsletter</h2>
									</div> {/* /.title-style-one */}
								</div>
								<div className="col-lg-6">
									<div className="form-wrapper">
										<form action="#">
											<input type="text" placeholder="Email address" />
											<button>Subscribe</button>
										</form>
										<p className="font-rubik">Already a member? <a href="login.html">Sign in.</a></p>
									</div> {/* /.form-wrapper */}
								</div>
							</div>
						</div> {/* /.bg-wrapper */}
					</div> {/* /.container */}
				</div> {/* /.fancy-short-banner-four */}

			</div>
		</>
	)
}

export default About
